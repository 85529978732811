// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:        #00498f;// #27ae60;
$dark-blue:     #00498f;
$brand-yellow: #ffd200;
$highlight-blue: #44c8f5;
$link-color: $brand-primary;
$link-hover-color: darken($link-color,15%);
// animations

 /* Chrome, Safari, Opera */ 
@-webkit-keyframes fadeinSlideUp {
    0%    {margin-top: 40px; opacity: 0;}
    40%   {margin-top: 40px; opacity: 0;}
    100% {margin-top: 0px; opacity: 1;} 
} 

/* Standard syntax */ 
@keyframes fadeinSlideUp {
    0%    {margin-top: 40px; opacity: 0;}  
    40%   {margin-top: 40px; opacity: 0;}
    100% {margin-top: 0px; opacity: 1;}
}