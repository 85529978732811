//Main Section Index Template
main.main-content-section{
  ul li{
    font-size: 18px;
  }
    @media (min-width:$screen-md-min){
        min-height: 500px;
    }
}
#main-image-cta {
  position: relative;
  z-index: 5;
  height:550px;
  @media (max-width:$screen-xs-max){
    height: 450px;
  }
  .flexslider{
      height: 100%;
  }
  .carousel-control {
    opacity: 1;
    color: $dark-blue;
    position: absolute !important;
    background: none;
    transition: 0.2s;
    &:hover {
      color: $dark-yellow;
    }
  }
	.banner-custom-navigation {
		a {
       //   background-color: rgba($dark-blue,.8);
          padding: 15px;
//        background: rgba(3,67,140,.8);
          transition: background .3s ease;
          font-size: 60px;
          color: $dark-blue;
          text-shadow: 0 0 1px #fff;
          background: none;
          border: none !important;
          height: 80px;
          width: 60px;
          text-align: center;
          @media (max-width:$screen-xs-max){
              padding: 0;
              width: 45px;
          }
          span{
              position: absolute;
              width: 100%;
              left: 0;
          }
			@media (min-width: $screen-sm-min) {
				display: block;
			}
			&:hover{
				color:#000;
			}
		}
      .flex-next{
       // border-top: 1px solid rgba(255,255,255,.4) !important;
        position: absolute;
        right: 0px;
        top: 260px;
        z-index: 9999;
      }
      .flex-prev{
        position: absolute;
//        right: 0px;
        top: 260px;
        z-index: 9999;
        transition:0.2s;
        @media (min-width:$screen-md-min){
            left: 160px;
        }
      }
	}
  .flexslider{
    padding:0px;
    margin:0px;
    border:none;
    overflow: auto !important;
  }

  .flex-control-nav{
    position: absolute;
    bottom: 10px;
    z-index: 99;
    top:auto;
    a{
      background: $dark-blue;
      border:1px solid $yellow;
      height:15px;
      width:15px;
      transition: 0.2s;
    }
    .flex-active{
      background: $yellow;
      border:1px solid $dark-blue;
      transition: 0.2s;
    }
  }

  .mainslider {
    height: 550px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    @media (max-width:$screen-xs-max){
        height: 435px;
    }
  }

  .slider-info {
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    top: 350px;
    right: 0px;
    border-radius: 2px;
    padding: 30px;
    //min-width: 300px;
   // max-width: 500px;
    @media (min-width:$screen-md-min){
		right: 15px;
    }
    @media (min-width:$screen-md-min) and (max-width:$screen-md-min + 60px){
        right: 66px
    }
    @media (min-width:$screen-lg-min) and (max-width:1490px){
        right: 66px
    }
    h2 {
      color: #444;
      font-size: 28px;
      text-transform: uppercase;
      margin-top: 0px;
	  line-height:1;
      font-weight: 300;
      span {
        color: $dark-yellow;
        display: block;
        margin-top: 5px;
        font-weight: 700;
      }
	  @media (min-width:$screen-md-min){
		font-size: 42px;
	  }
    }

    .about {
      font-size: 16px;
    }

    a {
      padding: 6px 30px;
      position: absolute;
      bottom: -15px;
      right: 14px;
	  font-size: 18px;
	  @media (max-width:$screen-xs-max){
    	  right: auto;
    	  left: 50%;
    	  transform: translateX(-50%);
	  }
    }
  }

  .info-left{
      transition: .3s all ease;
    @media (min-width:$screen-md-min){
        left:100px !important;
        right:auto !important;
    }
  }

  .info-center{
    position: relative !important;
    margin:0px auto !important;
    text-align: center;
  }

  .slider-info-dark {
    background:rgba(0,0,0,.3) !important;
    h2{
      color: #fff;
    }
  }

  .border-on{
    border: 1px solid rgba(3,67,140,.8);
  }


  .mainslider.flex-active-slide {
	.slider-info{
	  animation: 2.5s ease 0s normal forwards 1 fadeinSlideUp;
      @media (max-width:560px){
        animation: none;
      }
	}
  }
}
.hub-closed{
    .flex-prev{
    //  left:60px !important;
      transition:0.2s;
    }
    #main-image-cta .info-left{
        @media (min-width:$screen-md-min){
            left:0px !important;
            right:auto !important;
        }
      }
}





.index-temp{
  position: relative;
  z-index:1;
  margin-top:0px !important;
  h2, h1{
    margin-top:50px;
    @media (max-width:560px){
      margin-top:10px
    }
  }
}

.grid-area{
  margin-bottom: 75px;
  z-index: 1;
  position: relative;
  .index-grid{
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-decoration: none;
    *{
      font-family: $heading-font;
    }
    .inner{
      bottom: 20px;
      color: #fff;
      text-align: right;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      display: block;
      width: 80%;
      margin: 140px auto;
      position: relative;
      border-top: 2px solid #FFF;
      padding-top: 5px;
      transition:0.2s;
    }
    .inner-bg{
      background: rgba(3,67,140,.8);
      height:100%;
      width:100%;
      display: block;
      position:absolute;
      left:0px;
      opacity: 0;
      transition: 0.2s;
    }
    &:hover{

      .inner{
        color: $yellow;
        border-color: #fff;
        transition:0.2s;
      }
      p{
        background: none;
        transition: 0.2s;
      }
      .inner-bg{
        transition: 0.2s;
        opacity:1;
      }
    }
    p{
      background: rgba(3,67,140,.8);
      padding: 7px;
      transition: 0.2s;
    }

  }
  .open-account {

    .inner-bg{
      opacity: 1;
      background: rgba(255,255,255,.9);
    }

    &:hover{
      .inner-bg{
        background: rgba(3,67,140,.8);
      }

      .inner{
        color: #fff;
        transition: 0.2s;
      }
    }

    .inner{
      border:0px;
      bottom:70px;
      font-size: 34px;
      color: $dark-grey;
      span{
        color: $yellow;
        display: block;
        border-top:2px solid #fff;
      }
    }
  }
}

.cats{
  border:none !important;
  h2{
    text-align: center;
    margin:30px 0;
    color: #000;
    text-transform: uppercase;
  }
  .flex-viewport li{
    text-align: center;
    transform: scale(1);
    transition: 0.2s;
    &:hover{
      transform: scale(1.05);
      transition: 0.2s;
    }
    a{
      transition: .2s;
      display: block;
      height: 200px;
      @media (max-width:768px){
        background-size: contain !important;
      }
      @media (max-width:500px){
        //height: 300px;
        //background-position-y: -50px !important;
      }
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: 50% !important;


      h3{
        font-size: 18px;
        color: #294076;
        text-decoration: none;
      }

      &:hover{
        color:#000;
        transition: 0.2s;
        img{
          filter: grayscale(100%);
          transition: 0.2s;
        }
      }
      img{
        width:100%;
        transition: 0.2s;
      }
    }
  }

}
//Main Section Index Template
