//Child page template
#child-title{
  position: relative;
  z-index:20;
  h1 {
    font-family: Roboto, sans-serif;
    font-size: 42px;
    color: $dark-grey;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 700;
    span {
      color: $yellow;
      display: block;
    }
  }
}

#child-main{
  margin:10px 0 50px 0;
  position: relative;
  z-index:1;
  background: #fff;
  p{
    font-size:18px;
  }

  a{
    width:100%;
    display: block;
    margin:20px auto;
    padding: 20px;
  }
}

#links{
  position: relative;
  z-index: 1;
  background: #fff;
  h3{
    font-family: Roboto, sans-serif;
    font-size: 42px;
    color: $dark_grey;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 700;
    span {
      color: $yellow;
      display: block;
    }
  }

  p{
    font-size:17px;
  }

  .link-img{
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    display:block;
    transition: 0.2s;
    &:hover{
      filter:grayscale(1)
    }
  }
}

.text-only-panel,
.row--50-50{
  margin:30px auto;
  h2{
    font-size: 42px;
    color: $dark-grey;
    margin: 0 0 20px;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 700;
    span{
      color: #f1c600;
      display: block;
    }
  }
  p{
    font-size:18px;
  }
}

.img-text{
  margin:30px auto;
  position: relative;
  background: #fff;
  z-index: 10;
  h2{
    font-size: 42px;
    color: $dark-grey;
    margin: 0 0 20px;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 700;
    span{
      color: #f1c600;
      display: block;
    }
  }
  p{
    font-size:18px;
  }
  img{
    width:100%;
    margin-bottom:30px;
  }
}

//Child page template