#timeline-container{
  //margin:40px 0px;
}

h1{
  font-family: Roboto,sans-serif;
  font-size: 42px;
  color: $dark_grey;
  margin: 20px 0 20px;
  line-height: 1.1;
  text-transform: uppercase;
  font-weight: 700;
  span{
    color: $yellow;
    display: block;
  }
}


.cbp_tmtimeline {
  margin: 30px 0 30px 0;
  padding: 0;
  list-style: none;
  position: relative;
  p{
    font-size:18px;
  }
}

/* The line */
.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background: $dark-blue;
  left: 20%;
  margin-left: -10px;
}

.cbp_tmtimeline > li {
  position: relative;
}

/* The date/time */
.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 20px;
  color: $dark-blue;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: $dark-blue;
  margin-top: 11px;
  margin-right: 15px;
}

time span{
  font-size: 16px;
  margin-top: 11px;
  margin-right: 15px;
}

/* Right content */
.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: $yellow;
  color: $dark_blue;
  padding: 2em;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
  border-radius: 5px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: $dark-blue;
  color: #fff;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel h2 {
  background: $dark-blue;
  color: #ffd200 !important;
}

.cbp_tmtimeline > li .cbp_tmlabel h2 {
  margin-top: 0px;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  width:98%;
  color: $dark-blue;
}

/* The triangle */
.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: $yellow;
  border-width: 10px;
  top: 10px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: $dark-blue;
}

/* The icons */
.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  font-family: 'fontAwesome';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: $yellow;
  border-radius: 50%;
  box-shadow: 0 0 0 8px $dark-blue;
  text-align: center;
  left: 20%;
  top: 0;
  margin: 0 0 0 -25px;
}

.cbp_tmtimeline > li .cbp_tmicon .fa{
  color: $dark_blue;
}

.cbp_tmicon-phone:before {
  //content: "\f017";
}

.cbp_tmicon-screen:before {
  //content: "\f017";
}

.cbp_tmicon-mail:before {
  //content: "\f017";
}

.cbp_tmicon-earth:before {
  //content: "\f017";
}

/* Example Media Queries */
@media screen and (max-width: 65.375em) {

  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }

  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }

  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }

  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }

  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: $yellow;
    top: -20px;
  }

  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: $dark-blue;
  }

  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 5px 0 0px;
  }
}

.left-time{
  width:70%;
  display: inline-block;
  vertical-align: top;
}

.right-time{
  width:25%;
  display: inline-block;
  margin-left:2%;
  img{
    width:100%;
    vertical-align: unset;
  }
}
