//Global vars
$dark_blue: #00498f;
$light_blue: #66779d;
$yellow: #ffd200;
$dark_yellow: #f1c600;
$grey: #b4bac4;
$sub-menu: #b0d1ff;
$dark-grey: #444444;
$heading-font: "Roboto Condensed", sans-serif;
$acumin-italic:  "Roboto Condensed", sans-serif;
$acumin-regular:  "Roboto Condensed", sans-serif;
$roboto: "Roboto", sans-serif;



body{
  overflow-x: hidden;
  max-width: 100vw;
  font-size: 16px;
}

*{
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}
h1,h2,h3,h4,h5,h6{
  font-family: "Roboto Condensed", sans-serif !important;
  font-optical-sizing: auto !important;
  font-weight: 700 !important;
  font-style: normal !important;
  a,span{
    font-family: "Roboto Condensed", sans-serif !important;
    font-optical-sizing: auto !important;
    font-weight: 700 !important;
    font-style: normal !important;
  }
}
.fa{
  font-family: fontAwesome !important;
}

#hide-submenu ul.sub-menu{
  display: none;
}




//@font-face {
//  font-family: $heading-font;
//  src: url(../../font/Acumin-BdPro.otf);
//}
//
//@font-face {
//  font-family: $acumin-italic;
//  src: url(../../font/Acumin-ItPro.otf);
//}
//
//@font-face {
//  font-family: $acumin-regular;
//  src: url(../../font/Acumin-RPro.otf);
//}
//Global vars END

.flexslider{
      border-radius: 0;
  }

.flex-control-nav{
  position: relative;
  top:10px;
}

.flex-direction-nav{
  a{
    opacity:1;
    position: relative;
  }
}

.flexslider{
  z-index: 99;
}

.flexslider-carousel{
  position: relative;
}

.flex-direction-nav a{
  position: absolute;
  height:50px;
}

.flex-direction-nav .flex-prev:before{
  font-family:fontAwesome;
  content:"\f053";
  transition: 0.2s;
  color: $dark-blue;
  &:hover{
    color: #000;
    transition: 0.2s;
  }
}

.flex-direction-nav .flex-next:before{
  font-family:fontAwesome;
  content:"\f054" !important;
  transition: 0.2s;
  color: $dark-blue;
}

@media (min-width: 1200px){
  .container {
    max-width: 1400px;
    width: 100%;
  }
}


.blue-line {
    height: 120%;
    position: absolute;
    width: 190px;
    left: -20px;
    top: -10%;
    border: 1px solid $dark-blue;
    border-right: 0px;
    pointer-events: none;
}
.top {
    position: absolute;
    border-right: 1px solid $dark-blue;
    right: 0px;
    height: 100px;
    top: -100px;
    z-index: 0;
}

.bottom {
    position: absolute;
    border-right: 1px solid $dark-blue;
    right: 0px;
    height: 100px;
    bottom: -100px;
    z-index: 0;
}

.no-top{
    border-top:none;
}

[data-aos=slide-left]{
    transform: translateX(130%);
}
