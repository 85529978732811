@media (max-width:$screen-sm-max){
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    input[type="date"],
    textarea,
    select,
    select option{
        font-size: 16px !important;
    }
    
    .static-xs{
        position: static !important;
    }
}
@media (max-width: 1650px) {
  .socials {
    span {
      display: none;
    }
  }
}

@media (max-width: 1300px){
  .bottom-rows{
   display: block;
    width:100%;
  }
  .bottom-imgs .img-container .inner-box{
    left:0px;
    padding: 20px 50px 40px 20px;//20px 30px 30px 0px;
    top:35%;
  }

  .lower-nav{
    border:none;
  }
}
@media (min-width:$screen-md-min){
    .bottom-imgs .img-container .inner-box{
        padding-left: 15px;
        left: 100px;
        max-width: calc(100% - 115px);
    }
}

@media (max-width: $screen-md-max) {
  #newsletter h3{
    width:100%;
    font-size:36px;
    padding-left:0px;
    margin-left:55px;
  }
  .main-address .fa{
    display:none;
  }
  #national{
    display:none;
  }
}

@media (max-width: 1150px) {
  #side-nav #toggle-btn {
    font-size: 28px;
    text-align: center;
    margin: 0 auto;
    width: 100px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background: #ffd200;
    height: 30px;
    position: relative;
    line-height: 34px;
    cursor: pointer;
    transition: .3s;
  }
}

@media (max-width: $screen-sm-max) {
  header {
    #toggle-btn{
      display: none;
    }
    #sub-text{
        .basket-btn{
        //  display: none !important;
/*
            .picklist-btn{
                padding-left: 10px;
                padding-right: 10px;
            }
*/
            span{
                color: $dark-blue;
                float: left;
                padding-right: 5px;
            }
        }
    }

    .top-nav-area {
      padding: 0px;
      .container {
        width: 100%;
      }
      #sub-text{
        margin-top:5px;
      }
      ul.nav-sub-menu {
        display: none;
      }
    }
  }


  #menu-toggle{
    font-size: 25px;
    color: #fff;
    position: absolute;
    top: 0;//45px;
    cursor: pointer;
    margin-left: 10px;
    display: inline-block;
    right:15px;
    @media (min-width:$screen-sm-min){
        top:45px;
    }
  }

  .menu-open{
    display: block !important;
    //z-index:85 !important;
    z-index: 1000 !important;
  }

  .menu-open ul{
    top:0px !important;
  }

  .mobile-menu {
    padding-top: 207px;//125px;
    text-align: right;
    position: absolute;
    width: 100%;
    z-index: 0;
    display: block;
    transition: 0.7s !important;
    visibility: hidden;
   // padding-top: 125px;
    @media (min-width:$screen-sm-min){
        padding-top: 135px;
    }
    @media (max-width:768px){
      padding-top: 167px;
    }
    //ul {
      
      background: darken($dark-blue, 2%);
      top:-2500px;
      pointer-events: none;
     // position: relative;
      transition: 0.7s !important;
      &.menu-open{
          top:0;
          pointer-events: auto;
        visibility: visible;
      }
      ul {
          margin: 0px;
          padding: 10px 0;
          background: $dark-blue;
          &:first-child{
                border-top: 2px solid $yellow;
          }
          li {
            list-style: none;
            text-align: left;
            padding-left:25px;
            background: $dark-blue;
            a {
              color: #fff;
              //margin: 10px 0;
              padding:15px 0;
              display: block;
              font-size: 16px;
              text-decoration: none;
            }
            &.active{
                background: $dark-blue;
                a{
                    color: #fff;
                }
            }
            .sub-menu{
              margin-top:0px;
              padding-top:0px;
              display:none;
              background: $dark-blue !important;
            }
          }
    }


  }

  //homepage
  .blue-line, .footer-line{
    display: none;
  }

  .img-cta{
    margin-top:-420px;
    text-align:center;
    .image-link{
      text-align: center !important;
    }
    .large{
      font-size:35px !important;
    }
  }

  .home-box{
    height: 200px !important;
    margin-bottom:6px;
    border-top:0px !important;;
  }

  #main_banner{
    h2{
      font-size:36px;
      margin-top:25;
      span{
        font-size:36px;
      }
    }
  }
  .bottom-imgs .img-container .inner-box{
    top:35%;
    display: block;
    left:0px;
    text-align: center;
  }
  //homepage

  #newsletter h3{
    font-size:30px;
  }

  #footer-links{
    margin-top:20px;
  }

  #footer-links ul{
    columns: auto;
  }

  #main-image-cta .slider-info{
    position: relative;
    width: auto;
    max-width:calc(100% - 75px);
    padding: 15px 15px 25px 15px;
   // max-width:none;
    top:260px;
    text-align:center;
    left: 50%;
    transform: translateX(-50%);
    h2{
        font-size: 19px;
    }
  }

  //Woocommece Pages
  .woocom-archive .below-filter input{
    padding: 10px 53px !important;
    text-align: center;
    margin: 10px auto !important;
    display: block;
    float:none;
    width:100%
  }

  .cat-description{
    margin-top:20px;
  }

  .single-product .entry-summary .downloads p{
    text-align: center;
  }

  .single-product .find-depot form{
    width:100%;
  }

  .single-product .single-product-main-image{
    height: auto;
  }
  //Woocommece Pages

  //Locations
  #ml-locations .locations-container{
    height: auto;
    margin-bottom:30px;
  }
  .locations-container .alt-numbers{
    clear:both
  }
  //Locations

  #posts-template .postitem{
    width:49%;
  }

  .btns a, .btns button, #quote{
    width:100% !important;
  }

  #iframe-vid{
    height:380px;
  }

  .single-product h1{
    margin-top:20px;
  }
  
  footer{
/*
    #sidebar_footer_ctas{
      display: none;
    }
*/
    .links-container{
      width:100% !important;
      padding-left:0px !important;
      padding-right:0px !important;
    }
    #footer-links{
      margin-top:0px !important;
    }
    #newsletter{
      text-align: center;
      @media (max-width: 550px) {
        padding-bottom:30px !important;
      }
    }
    h3{
      text-align: center !important;
      margin:5px 0 !important;
    }
    p.text-right{
      text-align: center !important;
    }
  }
  .main-address .fa{
    display:block;
  }
  .copyright{
    padding:0 15px;
    color: #000;
    @media (max-width: 550px) {
      padding:20px 15px !important;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .top-nav-area .search {
    display: none !important;
  }

  .woocom-archive ul.products li.product{
    width: 48% !important;
    float: left !important;
    clear: both;
    margin:0 1% 20px 1% !important;
  }
  .addtoany_share_save_container{
    top:160px;
  }

  #posts-template .locations-container{
    text-align: center;
    .title{
      margin-top:20px !important;
    }
  }
  .left-time, .right-time{
    width:100%;
    margin:0px;
    display: block;
  }
  #slider-product .slides li{
    height:380px;
  }
  .header-phone{
    text-align: right;
    margin-right:15px;
    margin-top: 10px;
    @media (min-width:$screen-sm-min){
        margin-top: 5px;
    }
    position: relative;
    top:50px;
    
    position: absolute;
    right: 0;
    top:100%;
    padding-top: 0;//7px;
  }
  .top-nav-area #site-logo img{
    margin-top:15px;
  }
  #menu-toggle{
   // top:-40px;
  }
  .social-container{
    position: absolute;
    top:0px;
   // right:50px;
   // text-align: right;
  }
}


@media (max-width: 600px) {
  .copyright ul, .copyright #blue2{
    float:none;
    text-align: center;
    display: block;
    margin:15px auto;
  }

  //Template Main
  .index-grid{
    width:100%;
    height:300px;
    display: block;
    margin-bottom:7px;
  }
  //Template Main
  .img-cta #main-text h1{
    line-height: 1.4;
  }

  #posts-template .postitem{
    width:100%;
  }
  .header-phone{
      padding-top:7px;
  }
  .top-nav-area .header-phone span#num{
    font-size:20px !important;
  }
  #menu-toggle {
 //   top: -27px;
  }
}

@media (max-width: 500px) {
  .img-cta #main-text h1 {
    font-size: 40px;
    .large {
      font-size: 20px !important;
    }
  }

  #header-phone #num{
    font-size:20px;
  }

  #national {
    display: none;
  }

  .woocom-archive ul.products li.product {
    width: 100% !important;
    margin-bottom: 35px !important;
    //@media(max-width: 500px){
    //  margin-bottom: 0px !important;
    //}
  }

  #menu-toggle{
    //position: absolute;
    //top: 45px;
    //right: 15px;
  }

  #phon{
    text-align: left;
    padding-left:0px;
    margin-left:0px;
    .header-phone{
      position: relative;
      text-align: right;
      top: 9px;
      margin-bottom: 10px;
      margin-right: 15px;
    }
  }

  #iframe-vid{
    height:300px;
  }


}


@media (max-width: 550px) {
  .contact-details{
    text-align: center;
    margin:40px auto;
    .col-xs-6{
      display: block;
      float:none;
      width:100%;
      text-align: center;
      clear: both;
      margin:0 auto;
      margin-bottom:10px;
    }
    .main-address{
      overflow: hidden;
      padding:0px;
      .fa, div{
        float:none;
        display: inline-block;
      }
    }
  }
}


@media (max-width: 400px) {
  #site-logo img{
    min-width:170px !important;
  }

  #iframe-vid{
    height:180px;
  }

  #slider-product .slides li{
    height:330px;
  }

  #menu-toggle {
   // right:0px;
  }
  .social-container{
   // right:30px;
  }
}


#main-image-cta{
    @media (max-width:560px){
       border-top: 166px solid $dark-blue;
       height: 435px;
    }
    .mainslider{
       @media (max-width:560px){
           height: 140px;
           margin-bottom: 125px;
           .container{
               height: 100%;
           }
        } 
    }
    .flex-control-paging{
        @media (max-width:560px){
            display: none;
        }
    }
    .container .slider-info{
        @media (max-width:560px){
            background: #fff;
            top: 100% !important;
            width: 100%;
            max-width: none;
        }
    }
    .banner-custom-navigation{
        @media (max-width:560px){
            .flex-prev,
            .flex-next{
                top:175px;
            }
        }
    }
}


@media (max-width: 1200px){
  .logo-area{
    height:60px;
  }
}