//Post Templates
#posts-template{
  .controls{
    margin-bottom:20px;
  }
  h1{
    color: $dark-grey;
  }

  .page-top{
    margin-bottom: 10px;
    p{
      font-size: 18px;
    }
  }

  .postitem{
    display: inline-block;
    float:none;
    //width:33.33%;
    margin-bottom:40px;
    margin-right: -4px;
    vertical-align: top;
    p{
      font-size: 15px;
      color: #000;
      margin:0px 0px 2px 0px;
      min-height: 22px;
      @media (max-width:600px){
        min-height: 1px;
      }
      a{
        color: #000;
        transition: 0.2s;
        &:hover{
          color: $dark-blue;
        }
      }
    }
    .name{
      margin-top:5px;
    }
    .about{
      display: none;
    }
    .division{
      min-height:50px;
    }
    .img{
      //height: 350px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding-bottom: 100%;
    }
    .has-link{
      cursor:pointer;
    }
    .info-btn{
      width: 100%;
      display: block;
      margin-top: 10px;
    }
  }

  .postitem-people {
    width: 25%;
  }

  .control{
    background: $dark_blue;
    color: #fff;
    width:100%;
    display: block;
    transition: 0.2s;
    border: none;
    padding: 10px 0;
    margin-bottom:2px;
    outline: 0px !important;
  }
  .mixitup-control-active, .control:hover{
    background: $dark-yellow;
    color: $dark-blue;
    transition: 0.2s;
  }
}


.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  @media (max-width:768px) {
      margin:0px;
      width:96%;
  }
}

.modal.fade .modal-dialog {
  transform: translate(0, -100%);
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-header{
  background: $dark-blue;
  color: $dark-yellow;
  .close{
    color: $yellow;
    opacity:1;
  }
  span{
    font-size:14px;
  }
  div{
    display: inline-block;
    margin-right:10px;
  }
  .fa{
    margin-right:5px
  }
  a{
    margin-right:10px;
    color:$yellow;
    margin-top:5px;
    display:inline-block;
  }
}
//Post Templates

//news posts
.news-posts{
  .title{
    min-height:45px;
  }
  .exc{

  }
.name{
  font-weight:bold;
}
.date{
  font-size:12px !important;
}
  .excerpt{
    min-height:70px;
    a{
      display: none;
    }
  }
}

    //single news post
#news-content{
}
     .embed-container {
       position: relative;
       padding-bottom: 56.25%;
       overflow: hidden;
       max-width: 100%;
       height: auto;
     }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    //single news post
//news posts

//Downlaods
.download-btn{
  width:100%;
  margin-bottom:20px;
  .fa{
    margin-right:5px
  }
}
//Downlaods