// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.container-wide-auctions {
  @extend .container;


  @media(min-width: 1600px) {
    max-width: 1900px;

    .small-panel {
      margin-left: 0;
      width: 20%;
      float: left;
      padding: 0 15px;
    }
  }
}
