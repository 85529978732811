.btn-grey{
  background:rgba(180, 186, 196, 0.9);
  border:1px solid $dark_blue;
  border-radius: 20px;
  color: $dark-blue;
  transition: 0.2s;
  &:hover{
    background:rgba(255, 255, 255, 0.9);
    transition: 0.2s;
  }
}

.btn-blue{
  background: $dark-blue;
  border:1px solid $dark-blue;
  border-radius: 10px;
  color: #fff;
  transition: 0.2s;
  &:hover, &:focus, &:active{
    background: rgba(255,255,255,0.85);
    transition: 0.2s;
  }
}

.btn-yellow{
  background:rgba(255, 210, 0, 0.9);
  border-radius: 20px;
  color: $dark-blue;
  transition: 0.2s;
  &:hover, &:focus, &:active{
    background: $dark_blue;
    color: $yellow;
    transition: 0.2s;
  }
}

.btn-secondary {
	display:inline-block;
	padding:15px; 
	border: 1px solid $yellow;
	  background:rgba(255, 210, 0, 0.9);
	  border-radius: 20px;
	  text-transform: uppercase;
	  font-size:13px; 
	  font-weight:700;
	  color: $dark-blue;
	  transition: 0.2s;
	  &:hover, &:focus, &:active{
		background: #fff;
		color: $dark-blue;
		text-decoration:none;
		transition: 0.2s;
	  }
}