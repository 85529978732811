//Woocommerce custom//

.basket .shop_table tr td::before{
  content: '' !important;
}



.basket table.shop_table_responsive tr td{
  text-align:left !important;
}

.basket .product-remove {
  position: absolute;
  right: 15px;
  margin-top: -38px;
}

.woocommerce-ordering{
  z-index: 70 !important;
}
.addtoany_share_save_container{
 .addtoany_header{
   font-size:14px;
   font-weight:bold;
 }
}

.addtoany_share_save_container a{
    display:inline-block;
    line-height:2
}

.woocommerce-page #top-image-main{
    display: none;
}

.woocommerce-page .woocommerce-breadcrumb{
    color: $dark_yellow;
    font-weight: bold;
    font-size:15px;
    a{
        color: dark_blue;
        text-decoration: none;
        &:hover{
            color: $dark_yellow;
        }
    }
}

.woocommerce-page .price{
    display: none;
}

.woocommerce-message{
    //position: absolute;
    //top:170px;
    border-color: $yellow;
    a{
        margin-left:50px !important;
        background: $dark_blue !important;
        border:1px solid $dark_blue;
        color: $yellow !important;
        transition: 0.2s;
        &:hover{
            background: $yellow !important;
            color: $dark_blue !important;
            transition: 0.2s;
        }
    }
}

.product_meta{
    top: -40px;
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color:#fff;
    a{
        color: #fff;
        margin-right:5px;
        text-decoration: none;
        transition: 0.2s;
        &:hover{
            color: $dark_blue;
            transition: 0.2s;
        }
    }
}

.woof_products_top_panel{
  a{
    color: $dark-blue;
    transition: 0.2s;
    &:hover{
      color: #000;
      transition: 0.2s;
    }
  }
}

.woocommerce div.product div.summary{
  margin-bottom:0px;
}

#archive-title{
  margin-top:0px;
  font-size:22px;
  color: $dark_grey;
  span{
    display: none;
  }
}

.woocom-archive {
    margin-top:20px;
    .products li.product {
        width: 32% !important;
        float:none !important;
        display: inline-block;
        vertical-align: top;
        margin:0 0.4% 50px 0.4% !important;
      h2{
        color: $dark-blue;
        font-size: 22px !important;
        font-weight: bold;
      }
      .details{
        color: #000;
        font-weight: bold;
      }
      .exc{
        min-height:80px;
        color: #000;
        font-size:14px !important;
      }
  }

  .filter-inner {
    border:1px solid $dark_blue;
    padding-bottom:20px;
    h3{
      color: $dark_blue;
      font-size: 16px;
      padding-left: 20px;
    }

    select{
      padding: 8px;
      margin: 3% 7%;
      width: 85%;
      display: block;
      box-sizing: border-box;
      box-shadow: none;
      border: 1px solid rgb(150,150,150);
      border-radius: 50px;
      background-color: #f4f4f4;
      background-image: url(../images/select-arrow-grey.png);
      background-repeat: no-repeat;
      background-position: 95% center;
      background-size: 15px;
      font-weight: 600;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  #filter-links{
    padding-left: 20px;
    a{
      display: block;
      color: $dark_blue;
      transition: 0.2s;
      text-decoration: none;
      &:hover{
        transition: 0.2s;
        color: $dark_yellow;
      }
    }
  }

  .orderby{
    padding:10px;
    @media (max-width:992px){
      font-size:16px;
      padding:3px
    }
  }

  .woocommerce-result-count{
    font-size:16px;
    @media (max-width:500px){
      width:100%;
      text-align: center;
    }
  }

  .woocommerce-ordering{
    @media (max-width:500px){
      width:100%;
      margin:5px auto 20px auto;
      select{
        width:100%;
      }
    }
  }

  .above-filter{
    position: relative;
    background-size:cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height:150px;
    img{
      position: absolute;
      bottom:25px;
      left:15px;
    }
    #icon-line{
      position: absolute;
      bottom: 10px;
      z-index: 50;
      width: 90%;
      left: 5%;
      margin: 0px;
    }
  }

  #side-news{
    border:1px solid $dark-blue;
    margin-top: 15px;
    padding: 15px;
    h3{
      margin:0px 0 10px 0;
      text-align: center;
    }
    a{
      width:100%;
      font-size:20px;
      background: rgba(255,210,0,.9);
      color: #00498f;
      border:1px solid rgba(255,210,0,.9);
      &:hover{
        background: #fff;
        color: #00498f;
      }
    }
  }

  .inner-filter{
    position: absolute;
    top:0px;
    height:100%;
    width:100%;
    background: rgba(3,67,140,.9);
  }

  .below-filter{
    position: relative;
    background-size:cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height:150px;
    margin-top:20px;
    z-index:0;
    input{
      padding: 10px!important;
      width: 100%;
      float: right;
      margin-top: 10px;
      font-size: 18px;
      color: #000;
      @media (min-width:$screen-lg-min){
          width: 80%;
      }
    }
    h2{
      color: #fff;
      margin:0px;
      font-size: 21px;
      text-transform: uppercase;
      position: relative;
      z-index:99;
      padding:15px;
      @media (min-width:$screen-lg-min){
          font-size: 26px;
      }
      span{
        color: $dark-yellow;
        display: block;
      }
    }
  }

  #signup{
    .btn{
      background: $dark-yellow;
      color: $dark-blue;
      width:100%;
      transition: 0.2s;
      border-radius: 1.65em;//10px;
      margin:20px 0px;
      &:hover{
        background: #fff;
        transition: 0.2s;
      }
    }
  }

  .location-time-banner{
    border-top:1px solid $dark-blue;
    border-bottom:1px solid $dark-blue;
    padding:8px 0;
    margin-bottom:10px;
    ul{
      padding-left:0;
      margin-bottom:0;
      li{
        display:inline-block;
        color:$dark-blue;
        &:first-child{
          margin-right:20px;
        }
        .fa{
          color:#ADB5BD;
        }
      }
    }
  }

}

.products{
  li{
    text-align: center;
  }
  h3{
    color: $dark_blue;
    font-weight: bold;
    font-size:18px;
    padding:0px;
    margin:0px;
    min-height:40px;
    margin-bottom:10px;
  }
  a.button, form button.quote{
    background: #294076;
    color: #fff;
    border-radius: 1.65em;//15px;
    text-transform: uppercase;
    padding: 15px 5px;
    width: 80%;
    border:1px solid $dark_blue;
    transition: 0.3s;
    text-align: center;
    &:hover{
      background: #fff;
      color: $dark_blue;
      transition: 0.3s;
    }
  }
  p{
    color: #000;
  }
}

.shop_attributes a{
  color: #000;
}
.single-product #cat-specific-panel{
  .shop_attributes{
    .attribute-heading-row{
      background: $yellow;
      cursor: pointer;
      max-height: 100px !important;
      border-bottom: 7px solid white;
      position: relative;
      th{
        font-size: 18px;
        font-weight: bold;
        padding: 10px;
        position: relative;
        text-transform: uppercase;
        width: 100%;
        span{
          padding: 0 !important;
          max-height: 100px !important;
        }
        &:after{
          position: absolute;
          right: 10px;
          top: 10px;
          content:"\f078";
          font-family: fontAwesome;
          transition: .3s all ease;
        }
      }
      ~ .accordion-content{
        >*>*{
          display: block;
          position: relative;
          overflow: hidden;
          max-height: 0;
          width: 100%;
          padding: 0 10px;
          transition: .2s all ease;
        }
        &--open{
          >*>*{
            max-height: 100px;
            padding: 10px 10px;
          }
        }
      }
      &.accordion--closed{
      }
      &.accordion--open{
        th{
          &:after{
            // content:"\f077";
            transform: rotate(180deg);
          }
        }
      }


    }
  }
}

.single-product{
  h1{
    margin-top:60px;
  }

  .product_title{
    text-transform: uppercase;
    margin:10px 0
  }
  .images{
    float:none !important;
    width:100% !important;
  }
  .entry-summary{
    float:none !important;
    width:100% !important;
    // min-height:630px;
    min-height: 470px;
    border-bottom:1px solid #000;
    form.cart{
      margin-bottom:0px !important;
    }

    .btns{
      overflow: auto;
      margin-bottom:10px;
    }

    @media (max-width:992px) {
      .add-btn {
        margin-bottom: 0px;
      }
    }

    .woo-btn{
      width:80%;
      color: #000;
      padding: 10px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 1.65em;//12px;
      margin-top:10px;
      &:hover{
        color: #fff;
      }
    }

    .available-btn{
      color: #fff;
      font-weight:300;
      &:hover{
        color: #000;
      }
    }

    #picklist{
      margin-right:5%
    }
    .downloads{

    }
    hr{
      border-top:1px solid #000;
      margin-top:30px;
    }
  }



  //Seperatte Panels
  #cat-specific-panel{
    form{
      position: relative;
    }
    margin-bottom:20px;
    clear: both;
    padding: 15px;
    .pcode{
      font-family: ariel !important;
      width:100%;

    }
    h3{
      font-weight:bold;
      font-size:24px;
      text-transform: uppercase;
    }
    table{
      border:none !important;
      td{
        width:50%;
        border:none;
        font-weight: bold;
        margin:0px;
        padding:0px;
        line-height:1;
        font-style: normal;
      }
      th{
        width:50%;
        border:none;
        font-weight: 300;
        margin:0px;
        padding:0px;
        line-height:1;
      }
    }
  }

  #techhr{
    border-top:1px solid #000;
    margin:0px !important;
  }

  #tech{
    text-align: center;
    text-transform:uppercase;
    font-weight: bold;
    margin:10px 0 15px 0;
  }

  .prop{
    h4{
      font-weight: bold;
      margin:10px 0 3px 0;
    }
    p{
      padding:0px;
    }
  }

  .prop-map{
    .acf-map{
      margin:0px 0 20px 0;
      height: 200px;
      width: 100%;
      border: #ccc solid 1px;
    }
    .acf-map img {
      max-width: inherit !important;
    }
  }

  //Seperatte Panels

  .product-video{
    margin-bottom: 20px;
  }

  .find{
    margin-top: 20px;
    //padding-right:0px;
  }
  .find-depot{
    clear: both;
    position:relative;
    padding: 30px 20px;
    text-transform: uppercase;
    .find-inner{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      background:rgba(3,67,140,.7)
    }
    p {
      color: #fff;
      font-size: 32px;
      font-weight: bold;
      line-height: 0.9;
      span {
        color: $yellow;
        display: block;
        margin-top:5px
      }
    }
    input{
      padding:10px;
    }

    form{
      font-family: fontAwesome;
      background: #fff;
      width: 90%;
      margin-top:15px;
      input{
        padding: 10px;
        background: none;
        border: none;
        text-align: left;
      }
    }
  }

  .flexslider-carousel {
    position: relative;
    width: 90%;
    margin: 0 auto;
  }

  .cross-sells{
    background:#fff !important;
    border-top:none !important;
    margin:0px !important;
  }

  #related-products{
    margin-top:50px;
    @media (max-width:992px){
      margin-top:0px;
    }
    //padding-bottom:50px;

    #related-head{
      text-align: center;
      padding: 15px 0;
      margin: 10px auto 40px auto;
      text-transform: uppercase;
      clear: both;
      border-top:1px solid #000;
      border-bottom:1px solid #000;
      font-weight: bold;
      color: $dark-grey;
    }

    .rel-btn{
      text-transform: uppercase;
      padding: 15px;
      display:inline-block;
      width:auto !important;
      font-weight: bold;
      letter-spacing: 1px;
      margin-top:20px;
    }

    .related-need{
      color: $dark-blue !important;
    }

    #accessories {
      background: #d8dcdd;
      text-align: center;
      padding: 50px 0;
      h3 {
        color: $dark_blue;
        font-weight: bold;
        font-size: 18px;
        padding: 0px;
        margin: 0px;
        min-height: 40px;
        margin-bottom: 10px;
      }
      a.button {
        background: #294076;
        color: #fff;
        border-radius: 1.65em;//25px;
        text-transform: uppercase;
        padding: 15px 30px;
        width: 80%;
        border: 1px solid $dark_blue;
        transition: 0.3s;
        &:hover {
          background: #fff;
          color: $dark_blue;
          transition: 0.3s;
        }
      }
      p {
        color: #000;
      }
    }
  }

  .img-pro{
    height: 200px;
    background-size: cover;
    // background-position: 21px;
    background-position: center bottom;
    background-repeat: no-repeat;
  }

  .bottom-imgs {
    .up-sells h2 {
      display: none
    }
    .products{
      width:50%;
      margin:0 auto;
      text-align: center;
      li{
        width:100% !important;
        clear: both !important;
        float:none
      }
    }
  }

  .woocommerce-breadcrumb{
    position: relative;
    z-index: 50;
    margin-top: 20px;
    margin-bottom:0px;
    a{
      color: #000;
    }
  }

  #cross-sells{
    text-align: center;
    padding-bottom: 50px;
    h2{
      margin:0 0 40px 0;
    }
    .carousel-control{
      background: none;
    }
    .left {
      left: -50px;
      color: #294076;
      width:50px
    }
    .right {
      right: -50px;
      color: #294076;
      width:50px
    }
  }
}

.tax-product #main-image-cta{
  @media (max-width:560px){
    height:395px;
  }
}

.added_to_cart{
  color:$dark-blue;
  display: block;
  transition: 0.2s;
  &:hover{
    color:#000;
    display: block;
    transition: 0.2s;
  }
}

.basket {
  .woocommerce a.remove {
    float: right
  }
  .cart_item {
    .product-name a {
      color: $dark_blue;
      transition: 0.2s;
      text-decoration: none;
      font-size:18px;
      &:hover {
        color: $yellow;
        transition: 0.2s;
      }
    }

    img{
      width:100px !important
    }
  }
  #picklist-btn {
    float: right;
    font-size: 14px;
    margin-bottom: 30px;
  }

  #picklist-form{
    float: right;
  }
}

#related-products{
  margin:50px auto 20px auto;
  .flex-viewport{
    padding-bottom:30px;
  }
  img{
    padding: 0 20px;
  }
  h3{
    //min-height: 55px;
    padding: 0 20px;
    font-weight:bold;
    color: $dark-blue;
  }
  p{
 //   min-height: 70px;
    padding: 0 20px;
  }
  .btn{
    width:80%;
    margin:0 auto;
    display: block;
  }
  .related{
    text-align:center;
    transition: 0.2s;
    font-size:14px;
    .related{
        display: none;
    }
    &:hover{
      transform: scale(1.05);
      transition: 0.2s;
    }
  }
}


//Woocommerce custom//
//woocommerce product archive header
  .cat-description{
    h2{
      margin:0px;
      color: $dark-grey;
      text-transform: uppercase;
      font-size:28px;
      span{
        color: $dark-yellow;
        display: block;
        margin-top:5px;
      }
    }
  }

.cat-offer{
  margin:20px 0 40px 0;
  h2{
    margin:0px;
    color: $dark-grey;
    text-transform: uppercase;
    font-size:28px;
    font-weight:700;
    span{
      color: $dark-yellow;
      display: block;
      margin-top:5px;
      font-size:22px;
    }
  }
  img{
    width:100%;
  }

  .btn{
    float:right;
    margin-top:20px;
    @media (max-width:768px){
      width: 100%;
      margin-bottom: 30px;
    }
  }
}

.woocommerce-Price-amount{
  font-size:22px;
  font-weight:700;
}
//woocommerce product archive header





//Request a quote page
.request-a-quote, .arrange-viewing{
  margin-bottom:30px;
  h1{
    color: $dark_grey;
    text-transform: uppercase;
    margin:0px;
  }
  .wpcf7-form{
    input, textarea{
      width:100%;
      padding:10px
    }
    label{
      margin:0px;
      color: $dark_blue;
      font-size:18px
    }
    p{
      margin:0px;
    }
    .wpcf7-submit{
      background: $dark-blue;
      border:1px solid $dark_blue;
      transition: 0.2s;
      border-radius:1.65em;//12px;
      color: #fff;
      &:hover{
        background: #fff;
        color: $dark_blue;
        transition: 0.2s;
      }
    }
  }
  img{
    width:100%;
    margin-top:20px
  }

}



// Picklist quote
#side-picklist{
  background: $dark-blue;
  padding: 25px;
  margin-top:25px;
  h3{
    color: #fff;
    margin:0px 0 20px 0;
  }
  ul{
    padding: 0px;
    li{
      list-style:none;
      a{
        color: #fff;
        transition:0.2s;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  .view-picklist{
    background: #fff;
    color: $dark-blue;
    transition: 0.2s;
    text-decoration: none;
    border:1px solid #fff;
    width:100%;
    border-radius:1.65em;//15px;
    &:hover{
      background: $dark-blue;
      color: #fff;
      transition: 0.2s;
    }
  }
}
// Picklist quote

//Request a quote page





// Search Results
.search-results-container{
  //margin:50px 0;
  //h1{
  //  color: $dark_blue;
  //  margin:0 0 10px 0;
  //}
  //font-size: 16px;
  a{
    color: #000;
    transition:0.2s;
    text-decoration: none;
    margin-bottom: 5px;
    font-size:18px;
    display: block;
    @media (max-width:600px){
      margin: 0 auto 20px auto;
      width:50%;
      text-align: center;
    }
    &:hover{
      color: $dark_blue;
      transition:0.2s;
    }
    img{
      margin-right:10px;
      @media (max-width:600px){
        display: block;
        width:100%;
        margin-bottom: 20px;
        height:auto !important;
      }
    }

  }
  .sub-search {
    .search{
      color: #fff;
      font-family: fontAwesome;
      font-size: 15px;
      display:inline-block;
      margin-right:20px;
      cursor:pointer;
      form{
        background: #13254e;
        padding: 8px;
        border-radius: 1.65em;//35px;
        input{
          background: transparent;
          color: $yellow;
          border:none;
          outline: none;
        }
        ::-webkit-input-placeholder{
          color: $yellow;
        }
        ::-moz-placeholder{
          color: $yellow;
        }
        :-ms-input-placeholder{
          color: $yellow;
        }
        :-moz-placeholder{
          color: $yellow;
        }
      }
    }

    span {
      color: #fff;
      font-size: 15px
    }
    a {
      color: $yellow;
      display: inline-block;
      font-size: 20px;
      margin-left:10px;
      transition: 0.2s;
      &:hover{
        transition: 0.2s;
        color: #fff;
      }
    }
  }
}
// Search Results

//404 page
.error404-container{
  h1{
    margin:0 0 10px 0;
    line-height:1.3;
  }
  ul{
    li{
      margin-bottom: 5px;
      a{
        color: #000;
        transition: 0.2s;
        text-decoration: none;
        &:hover{
          color: $dark_blue;
        }
      }
    }
  }
}

.error404{
  .stripe{
    background: #294076;
    height: 50px;
    width: 100%;
  }
  .grid-area{
    margin-top:20px;
  }
  @media (max-width:768px){
    .container{
      margin:0px 15px;
    }
  }
}

.main-404-content{
  font-size:18px;
}
//404 page


//Auction Template
.page-template-template-auctions {
  #top-image-main {
    display: none;
  }
  .auction-header {
    height: 250px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.auction-boxes{
  margin-top:50px;
  position: relative;
  z-index:10;
  a{
    text-decoration: none;
  }
  .inner-auc{
    height: 300px;
    padding:30px 50px;
    color: #fff;
    margin-bottom:30px;
    text-decoration: none;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    .auc-overlay{
      background: rgba(3,67,140,.8);
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0px;
      left: 0px;
    }
    h2{
      position: relative;
      z-index: 10;
      text-transform: uppercase;
      color: #fff;
      font-size: 30px;
      span{
        display: block;
        color: $yellow;
      }
    }
  }
  .inner-bottom{
    position: absolute;
    bottom:0px;
    width:100%;
    left:15px;
    .fa{
      font-size:44px;
      display: block;
      margin-bottom: 10px;
    }
    .cal-link{
      color: #fff;
      transition:0.2s;
      &:hover{
      color: $dark-yellow;
      transition:0.2s;
    }
    }
    .times{
      padding-bottom: 15px;
      h4{
        font-size:28px;
      }
      p{
        margin:0 0 5px 0;
        color: $dark-yellow;
      }
      span{
        margin:0 0 5px 0;
        color: $dark-yellow;
        display: block;

        &.days_left {
          color: #fff;
        }
      }
      a {
        color: #fff;
        &:hover {
          color: $brand-yellow;
        }
      }
    }
  }
}
//Auction Template

.btn-location {
  background-color: transparent;
  &:hover {
    color: $brand-yellow;
  }
}
//Locations Page

@media (max-width:$screen-sm-max){
    .locations-wrapper{
        display: flex;
        flex-direction: column;
      position: relative;
      z-index:0;
        .flex-last-order{
            order:2;
        }
        .flex-first-order{
            order: 0;
        }
    }
}

#ml-locations{
  #map {
    height: 500px;
    width: 100%;
    margin-bottom:30px;
    @media (min-width:$screen-md-min){
            height: 800px;
    }
  }

  h2{
    font-family: Roboto,sans-serif;
    font-size: 42px;
    color: #444;
    margin: 20px 0 20px 0;
    line-height: 1.1;
    text-transform: uppercase;
    font-weight: 700;
    span{
      color: $yellow;
      display: block;
    }
  }
  #locations-filter{
    input{
      display: none;
    }
    .filter-row{
      background: $dark_blue;
      color: #fff;
      padding: 10px;
      border-bottom: 1px solid #fff;
      transition: 0.2s;
      &:hover{
        background: $yellow;
        color: $dark_blue;
        transition: 0.2s;
        cursor: pointer;
      }
    }
  }
  .filter-row#current{
    background: $yellow;
    color: $dark_blue;
    transition: 0.2s;
    cursor: pointer;
  }

  .locations-container{
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    p{
      margin:0px 0 5px 0;
    }
    .title{
      font-family: $heading-font;
      font-size:22px;
      font-weight: bold;
      color: $dark-blue;
    }
    .address{
      font-size:18px;
    }
    .type{
      font-size:18px;
    }
    h3{
      margin:30px 0 0 0;
    }
    .opening, .about{
      font-size:18px;
    }
    .more-btn{
      @media (max-width: 768px) {
        width:100%;
        margin-top:10px;
      }
    }
    .more-info-drop{
      @media (max-width: 992px) {
        padding:15px;
      }
    }
  }
}

.locations-container:first-child {
  @media (min-width: 992px) {
    margin-top: 0px !important;
  }
}

#map {
  .location-link {
    font-size: 42px;
  }
}

.more-info-drop{
  text-align: left;
}

.alt-numbers{
  margin:10px 0;
}

.people-row{
  margin:10px 0;
  clear:both;
  .peep-img{
    max-width:130px;
    margin:10px auto;
  }
  .person{
    p.name{
      font-weight:bold;
      cursor:pointer;
    }
    img{
      cursor:pointer;
      transition: 0.2s;
      transform: scale(1);
      &:hover{
        transform: scale(1.05);
        transition: 0.2s;
      }
    }
  }

}
//Locations Page

// Main search
.main-find-depot {
    padding-left: 15px;
    padding-right: 15px;
    input {
        position: relative;
        width: 90% !important;
        width: calc(100% - 40px) !important;
        padding: 5px;
        margin-bottom: 20px;
        font-size: 20px;
        float: left;
        height: 45px;
    }
    button {
        font-size: 20px;
        background-color: $dark-blue;
        border-radius: unset;
        color: $yellow;
        width:40px !important;
        height: 45px;
        //top: -3px;
        position: relative;
        &:hover {
            color: #fff;
        }
    }
}
#sub{
  position: absolute;
  right:0px;
}

#slider-product{
  margin-bottom:10px;
  border:1px solid #000;
  .slides li{
    height: 400px;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    a{
      width: 100%;
      height: 400px;
      position: absolute;
      z-index: 111111111;
      display: block;
    }
  }
  .flex-direction-nav{
    top:60px;
    position: relative;
    a{
      //position: relative !important;
    }
  }
}

#carousel-product{
  li{
      height: 120px;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      cursor: pointer;
      border:1px solid #000;
  }
  .flex-direction-nav{
    display:none
  }
}

// Main search

//start of calendar

.event-thumb, .eventimg{
  @media (max-width: 1200px) {
    min-height: 180px;
    max-height:180px;
  }
  @media (max-width: 992px) {
    min-height:250px;
    max-height:250px;
  }
  @media (max-width: 768px) {
    min-height:auto;
    max-height:none;
  }
}

.eventsHeader {
    color: #3b69a2;
    font-size: 21px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding-bottom: 10px;
}

.calendar-wrapper, .calendar-wrapper-temp {
    @media (min-width: $screen-md-min){
        padding: 0 100px;
    }
  @media (max-width: 1024px){
    padding: 0 15px;
  }

    .calendarNav {
        &.btn-group {
            width: 100%;
        }
        .btn-wrapper {
            padding-top: 5px;
        }

        button {

            &.btn {
                background-color: transparent;
                width: 100%;
                text-align: center;
                padding: 0px;
                color: #000;
                border: none;
                &:focus {
                    outline: none;
                }

            }
            .circle-border {
                font-size: 20px;
            }
            &.btn-default {
                border: none;
                padding: 5px 0;
                &:active, &:hover {
                    background-color: #ffffff;
                    border-color: #ffffff;
                    box-shadow: none;
                }
            }
        }
    }


    #calendar {

        padding-bottom: 10px !important;
        #cal-slide-content a.event-item {
            color: black;
        }

        .cal-month-day {
            height: 50px;
        }

        .cal-month-box [class*=cal-cell], .cal-year-box [class*=span] {
            min-height: 50px;
            border-right: 0px solid #e1e1e1;
        }

        .cal-month-box .cal-row-fluid, .cal-year-box .row-fluid {
            border-bottom: 0px solid #e1e1e1;
            margin-left: 0;
            margin-right: 0;
        }

        .cal-month-box, .cal-week-box, .cal-year-box {
            border: 0px solid #e1e1e1;
            border-radius: 2px;
            padding-bottom: 10px;
        }

        .cal-month-day {
            span {
                position: relative;
                @media (max-width:$screen-xs-max){
                    font-size: 14px;
                    padding: 5px;
                    width: 30px;
                    height: 30px;
                    top: 10px;
                }
                @media (min-width:$screen-sm-min){
                    font-size: 17px;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    top: 5px;
                }
                border-radius: 50%;
                margin: 0 auto;
                float: none !important;
                text-align: center;
                display: block;
            }
            &.cal-day-inmonth {
                span {
                    opacity: 1;
                    color: #6f6e6e;
                }
            }
            &.cal-day-today {
                background-color: transparent;
                span {
                    color: #ffffff;
                    background-color: #d4913b;
                }
            }

            &.cal-day-outmonth span[data-cal-date] {
                opacity: 0.8;
                cursor: default;
                color: #F1C600 !important;
            }
        }
        #cal-slide-content {
            box-shadow: none;
            margin: 10px 0;
        }
        .events-list {
            @media (max-width:$screen-xs-max){
                //display: none;
                position: absolute;
                top:7px;
                left: 50%;
                transform: translateX(-50%);
                padding-left: 0;
                &:before{
                    content: "";
                    display: block;
                    //position: absolute;
                    top:1px;
                    left: 1px;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    //background: #032b4f;
                    opacity: .35;
                }
                a{
                    //display: none;
                }
            }
            .event{
                border: 0 none;
                border-radius: 50%;
                width: 5px;
                height: 5px;
            }
        }
        .cal-row-head {
            .cal-cell1 {
                padding: 5px 0 0;
                margin: 0 0 -5px;
            }
        }
        #cal-day-tick {
            @media (max-width:$screen-xs-max){
                width: 20px;
                height: 20px;
                display: block !important;
                i {
                    top: -2px;
                    left: -1px;
                }
            }
            @media (min-width:$screen-sm-min){
                width: 30px;
                height: 30px;
            }
            right: 0;
            bottom: 0;
            padding: 5px;
            z-index: 5;
            text-align: center;
            margin-right: 0;
            top: 0;
            background-color: #666;
            color: #fff;
            border-radius: 0;
            border: 0px;
        }
    }
}

.upcoming-events{
  .event-excerpt {
    min-height: 110px;
    overflow: hidden;
    @media (max-width:768px){
      min-height:auto;
    }
  }
  .btn{
    //width:100%;
  }
}

body.page-template-template-events {
    .upcoming-events,
    .featured-events{
      img{
        //min-height:210px;
      }
        .btn-blue{
            @media (min-width:$screen-lg-min){
                position: absolute;
                bottom: 0;
                margin-bottom: 5px;
            }

        }
    }

}
.upcoming-events, .featured-events {
    @media (min-width:$screen-md-min){
        overflow-y: hidden;
        overflow-x: hidden;
       // min-height: 400px;
    }

    img {
        padding-bottom: 10px;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        display: inline-block;

        a {
            color: $dark-blue;
            &:hover {
                color: $brand-yellow;
                text-decoration: none;
            }

          img{
            width:100%;
          }
        }

    }
    .event-date {
        float: right;
        padding: 10px 10px 0px;
        color: $dark-blue;
    }

    .btn-blue {
        padding: 6px 5px;
        margin-bottom: 20px;
        position: static;
        @media (min-width:$screen-lg-min){
/*
            position: absolute;
            bottom: 0;
*/
            margin-bottom: 5px;
        }
      @media (max-width:768px){
        width: 100% !important;
      }
    }

    .btn-right {
      float: right;
      margin-bottom:15px;
    }

    .btn-hw {
      width: 50%;
    }

	.event-wrap {
		min-height: 220px;
		padding-bottom: 25px;
	}
	.event-inner, .event-wrap{
    	>div:last-child{
        	@media (min-width:$screen-lg-min){
        	    height: 100%;
        	}
    	}
    	@media (min-width:$screen-lg-min){
        	p.event-excerpt{
            padding-bottom: 50px;
          }

          div.event-excerpt{
            margin-bottom: 50px;
          }
        }
/*
    	>div:first-child{
        	height: auto;
    	}
*/
	}
}

.upcoming-events {
  h4.event-title {
    max-width: 70%;
  }

  p.event-date {
    max-width: 30%;
    text-align: right;
  }
}

/*
.upcoming-events{
    >.row>div[class*='col-']{
        min-height: 0;
    }
}
*/
.featured-events {
  height: auto;
  margin-bottom: 15px;
  overflow-y: unset;
  padding-bottom: 15px;
  .event-inner{
    background: $dark-blue;
    overflow: hidden;
    padding:15px 0;
    min-height:290px;
    height: 100%;
    h4 {
      text-transform: uppercase;
      font-weight: 600;
      display: inline-block;

      a {
        color: $brand-yellow;
        &:hover {
          color: #fff;
          text-decoration: none;
        }
      }

    }
    p{
      color: #fff;
    }
    .btn-blue{
      background:#fff;
      color:$dark-blue;
      border:1px solid #fff;
      &:hover{
        background:$dark-blue;
        color:#fff;
      }
    }
  }
}

.calendarTemp {
  height: auto;
  overflow:hidden

}

.page-template-template-events {
  .control {
    width: 70%;
    margin: 0 auto;
  }
}

.calendar-wrapper-temp {
  padding: 0px;
}
//end of calendar
// Main search

// Sidebar filtering
#sidebar {
  padding-left: 0px;
  padding-right: 20px;

  .woof_container {
    padding-bottom: 0px;

    h4 {
      font-size: 15px;
      border: thin solid #3b69a2;
      padding: 5px;
      font-weight: 600;
      color: $dark-blue;
      position: relative;

      a {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        text-align: right;
        height: 100%;
        padding: 3px 10px;
        font-size: 20px;
      }
    }

    .woof_list {
      ul {
        padding-left: 20px;
      }

      li {
        padding: 0 !important;
      }

      label {
        padding-top: 0;
        padding-bottom: 0;
        font-size:12px;
      }
    }

}

.woof_submit_search_form_container {
  button {
    background-color: #fff;
    color: $dark-blue;

    &:hover {
      background-color: $dark-blue;
      color: #fff;
    }
  }
}
}



//Locations
.locations-container{
  img{
    @media (max-width:$screen-md-max){
      max-width:33vw;
    }
    @media (max-width:1024px){
      max-width:none;
    }
    width:100%;
    margin-bottom: 15px;
  }
  .main-details{
    .title{
      font-weight:bold;
      color: $dark-blue;
      font-size: 22px;
      margin:0px;
    }
    .address{

    }
    .type{
      font-size: 18px;
    }
    p{
      margin:0px 0 3px 0 !important;
    }
    }


  .numbers{
    margin-top:10px;
    font-size:18px;
    .fa{
      width:27px;
    }
  }

  .alt-numbers{
    margin-top:15px;
    font-size:18px;
    .fa{
      width:27px;
    }
    .alt{
      margin-bottom:15px;
      .service{
      }
    }
  }

  .infobtn{
    margin:0;
    padding:5px;
  }

  .hours{
    //text-align:right;
    font-size:18px;
    h3{
      margin: 0px 0 10px 0;
    }
  }
  @media (max-width:768px){
    padding:30px 15px !important;
  }
}

.main-find-depot{
  input{
    width:95%;
    -webkit-appearance: none;
    border-radius: 0 !important;
  }
  button{
    width:5%;
  }
}

//Single News
#news-content{
  margin:30px auto;
  h1{
    color: $dark-grey;
    margin-top:0px;
    margin-bottom:5px;
  }
  h2{
    color: $dark-yellow;
    margin-top:0px;
  }
  .main-post-content{
    margin-top:30px;
    a{
      color:$dark-blue;
    }
  }
  .flexslider .flex-viewport li{
    height:250px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
  p{
    font-size:18px;
    line-height:1.6;
  }
  ul{
    font-size:18px;
    line-height:1.6;
  }
}
//Single News

//Single Events
.events-content{
  #event-map{
    height:300px;
  }
  .dates{
    background: #ccc;
    padding:15px;
    overflow:hidden;
    p{
      margin: 0 0 5px 0;
    }
  }
}

//Single Events

//Newsletter Sign up
#news-sign-up{
  margin-bottom:30px;
  h3{
    color: $dark-grey;
    margin-top:0px;
  }
  p{
    font-size: 18px;
    line-height: 1.6;
  }
  form{
    margin-top:15px;
    .formEl {
      margin-bottom:10px;
      label {
        display: block;
        margin-bottom: 0px;
      }
      input {
        padding: 7px;
        width: 100%;
      }
    }
    .option-container{
      margin-left:30px;
    }
    .btn-submit{
      background: $dark-blue;
      border: 1px solid $dark-blue;
      color: #fff;
      transition:0.2s;
      width:100%;
      margin-top:10px;
      &:hover{
        transition:0.2s;
        background: #fff;
        color: $dark-blue;
      }
    }
  }
}

//Results Template
#results-container{
  p{
    font-size:18px;
    line-height:1.6;
  }
  .dataTables_wrapper{
    margin:20px auto;
  }
  .dataTables_length{
    float:left;
  }
  .dataTables_filter{
    float:right;
    margin-bottom:10px;
    input{
      margin-left:5px;
    }
  }
  .dataTables_info{
    float:left;
    font-weight:bold;
  }
  .dataTables_paginate {
    float:right;
    font-weight:bold;
    a{
      color: $dark-blue;
      margin:0 5px;
      cursor:pointer;
    }
  }
  th{
    cursor:pointer;
  }
}

#forms-container{
  margin-bottom:20px;
  p{
    font-size:18px;
    line-height:1.6;
  }
  form{
    input, textarea{
      width:100%;
      padding:10px
    }
    label{
      margin:0px;
      color: $dark_blue;
      font-size:18px
    }
    p{
      margin:0px;
    }
    .wpcf7-form-control-wrap{
        position: relative;

        select{
            background: url('../images/select-arrow.png') no-repeat;
            background-position: 97% center;
            background-size: 15px;
            border-color: rgb(204,204,204) !important;
            border-radius: 0;
            width: 100%;
            padding: 10px;
            -webkit-appearance: none;
        }
    }
    .wpcf7-submit{
      background: $dark-blue;
      border:1px solid $dark_blue;
      transition: 0.2s;
      border-radius:1.65em;//12px;
      color: #fff;
      &:hover{
        background: #fff;
        color: $dark_blue;
        transition: 0.2s;
      }
    }
  }
}

.dcwss.dc-wall .stream li.dcsns-facebook .section-thumb img {
    max-width: 100%;
    height: auto;
}

.cat-temp {
  p{
    font-size:18px;
  }
  .products li.product {
    width: 19% !important;
    margin: 20px 0.15% !important;

    h2 {
      @media (min-width: (501px)){
        min-height: 70px;
      }
      @media (min-width: (585px)){
        min-height: 50px;
      }
      @media (min-width: (768px)){
        min-height: 70px;
      }
    }

    .exc {
      @media (min-width: (501px)){
        min-height: 120px;
      }
      @media (min-width: (585px)){
        min-height: 100px;
      }
      @media (min-width: (768px)){
        min-height: 180px;
      }
      @media (min-width: (992px)){
        min-height: 140px;
      }
      @media (min-width: (1200px)){
        min-height: 120px;
      }
      @media (min-width: (1350px)){
        min-height: 100px;
      }
    }
  }

  .img-wrapper {
    // height: 260px;
  }
}

body form div.wpcf7-response-output{
  border:none !important;
  font-weight: bold;
  margin-top: 0px;
  padding: 0;
}

.open-account{
  p{
    font-size:18px;
  }
  .img-download img{
    transition: 0.3s;
    transform: scale(1);
    &:hover{
      transition: 0.3s;
      transform: scale(1.05);
    }
  }
  .account-download{
    margin:20px 0;
    font-size: 20px;
    font-weight: 700;
    @media (max-width:768px){
      width: 100%;
    }
    .fa{
      margin-right:10px;
    }
  }
  hr{
    margin:30px 0;
    border-top:1px solid #000;
  }
  .upload-row{
    margin-bottom:40px;
  }

  textarea{
    width:100%;
    border:1px solid $dark-blue;
    padding:10px
  }

  input{
    width:100%;
    padding: 10px;
    border:1px solid $dark-blue;
  }

  .wpcf7-submit{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    &:hover{
      color: #000 !important;
    }
  }
}

.auctions {
  .modal-dialog{
    @media (max-width:768px){
      width:100%
    }
  }

}

.auction-map {
  width: 100%;
	height: 400px;
	border: #ccc solid 1px;
	margin: 20px 0;

  img {
    max-width: inherit !important;
  }
}

#newsletterModal{
  z-index: 9999999;
  .modal-body{
    color: #000;
    border: 1px solid #000;
    padding: 15px;
    width: 95%;
    margin: 0 auto;
  }
  img{
    @media (max-width:600px){
      display:none;
    }
  }
  .modal-content{
    padding: 30px;
    @media (max-width:600px){
      padding: 20px;
    }
    h3{
      margin: 0;
      background: #fff;
      position: relative;
      top: -50px;
      padding: 0 20px;
      font-size: 24px;
      @media (max-width:600px){
        top:0px;
      }
    }
    .close{
      position: relative;
      z-index:10;
    }
    .button-row{
      float: right;
      margin-top: 20px;
      text-transform: uppercase;
      @media (max-width:600px){
        float:none;
      }
      button{
        display: inline-block;
        @media (max-width:600px){
          width:100% !important;
          margin-bottom:10px;
        }
      }
      form{
        display: inline-block;
        @media (max-width:600px){
          width:100% !important;
        }
      }
      button{
        font-size: 18px;
      }
      .sign-up{
        background: $yellow;
        color: #000;
        border:1px solid $yellow;
        &:hover{
          background:#fff;
        }
      }
    }
  }
  .news-mod{
    @media (max-width:600px){
      width: 100% !important;
      margin:0 auto;
      text-align: cent77er;
    }
  }
}

.upcoming-events a img{
  width:100%;
}

.backto{
  font-size:18px;
  a{
    color: $dark-blue;
  }
}

.main-content h1, .main-content h2, h1, .img-text h2, .text-only-panel h2,
.row--50-50 h1 ,.row--50-50 h2{
  @media (max-width:600px) {
    font-size: 32px !important;
  }
}

.row--50-50{
    margin: 30px auto;
    p{
        font-size: 18px;
    }
}
.page-template-template-landing-page {

  .main-content-section {
    clear: both;
  }
  .custom-header {
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0px;
    z-index: 999;
    padding: 20px 0;
    @media (max-width:992px) {
      text-align: center;
    }
    @media (max-width:560px) {
      height: auto;
      background: none;
      border-bottom:none !important;
    }
    img{
      width:100%;
      max-width:300px;
    }
    .top-right {
      text-align: right;
      font-size: 32px;
      @media (max-width:992px) {
        text-align: center;
        margin-top:20px !important;
      }
      h3 {
        margin: 0px;
        padding: 0px;
        font-size:32px !important;
      }
    }
  }

  .grid-area .index-grid{
    height:400px;
    .inner{
      margin-top:320px !important
    }
  }

  .text-banner{
    padding:20px 0;
    text-align: center;
    h4 {
      font-size: 40px;
      font-weight: bold;
    }
  }

  .inner-banner{
    padding:15px 0;
    text-align: center;
    background-color: rgba(3,67,140,.8);
    h4 {
      font-size: 30px;
      font-weight: bold;
      color: #fff;
    }
  }

  .info{
    position: absolute;
    display: inline-block;
    bottom:70px;
    background-color: rgba(3,67,140,.8);
    padding:20px;
    @media (max-width:600px) {
      display: block;
      bottom: 0px;
      left:auto !important;
      right:auto !important;
      padding:10px 0;
    }
    h2{
      margin-top: 0px !important;
      text-align:left;
      @media (max-width:600px) {
        text-align: center;
      }
      span{
        font-size:46px !important;
        color:#ffd200;
      }
    }
  }
  #main-image-cta{
    border-color: transparent;
    @media (max-width:767px) {
      height: 300px;
    }
  }

  #main-image-cta .mainslider .container{
    // display: none;
  }
  .modal, .modal-open{
    overflow: visible !important;
  }
  .modal-backdrop{
    display: none !important;
  }
}
