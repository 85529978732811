//Home Page
.home #main-image-cta .slider-info{
  top: 240px !important;
  right:15px !important;
}

#main-image-cta .slider-info{
  top: 353px !important;
  @media (max-width:768px){
    top: 242px !important;
  }
  padding:25px !important;
  right:0px !important;
}


.img-cta{
  margin-top: -310px;
  z-index:20;
  position: relative;
  .img-link{
    margin-top: 70px;
    padding: 15px 40px;
    text-transform: uppercase;
    border-radius: 30px;
    font-size: 18px;
  }

  #main-text{
    color: #fff;
    font-size: 19px;
    //margin-left: -30px;

    h1{
      font-weight:bold;
      font-size:55px;
      color: #fff;
      margin:0px;
      line-height: 95px;
    }

    .large{
      font-weight:bold;
      font-size:55px;
      color: $yellow;
      line-height: 16px;
    }
    a{
      background: yellow;
      font-size: 15px;
      font-weight: bold;
      border-radius: 40px;
      padding: 15px 30px;
      color: $dark_grey;
      position: absolute;
      bottom:-23px;
      right:30px;
      transition: 0.3s;
      border:1px solid $yellow;
      &:hover{
        background: rgba(3,67,140,.8);
        color: #fff;
      }
    }

  }
}

#home-images{
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-top:-100px;
  @media (max-width:560px){
    margin-top:-40px;
  }
  .container {
    position: relative;
    margin-top:50px;
  }

  .boxes{
    overflow: auto;
    z-index:10;
    position: relative;
    border:3px solid #fff;
    border-bottom:none !important;
	//border-top:5px solid rgba(255, 255, 255, 0.8);
	//border-left:5px solid rgba(255, 255, 255, 0.8);
	//border-right:7px solid rgba(255, 255, 255, 0.8);
  }

  .home-box {
    background-repeat:no-repeat !important;
    background-size:cover !important;
	background-color: $dark-blue;

    height: 350px;
    text-align: center;
    display: block;
    transition: 0.3s;
    color: #fff;
    position: relative;
	border:3px solid #fff;
	.box-image {
		display:block;
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		background-repeat:no-repeat;
		background-size:cover;
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		transition: 0.3s;
	}
    .colour-overlay{
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(3,67,140,.7);
      left: 0px;
      transition: 0.3s;
    }

    .over-text{
      font-family: $heading-font;
      position: absolute;
      bottom: 15px;
      display: block;
      width: 80%;
      max-width: 335px;
      border-top: 1px solid;
      padding-top: 5px;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-size: 25px;
      text-align: right;
      margin: 0 0 0 20px;
	  transition: 0.6s;
      .fa{
        margin-left: -16px;
        left: 20px;
        position: relative;
        color: $yellow;
     	transition: 0.6s;
      }

      .icon{
        position: absolute;
        left: 0px;
        bottom: 50px;
        transition: 0.6s;
      }

    }
	&:hover{
	  background-color: #fff;
      transition: 0.3s;
	  .box-image{
		-webkit-filter: grayscale(0%);
		filter: grayscale(0%);
	  }
      .colour-overlay{
        background: none;
        transition: 0.3s;
      }
	  .over-text{
		border-color:$dark-blue;
		background: $dark-blue;
		color:#fff;
		padding-right:15px;
		.fa {
			left:40px;
		}
		.icon{
			left:10px ;
			opacity:0;
			transition: 0.2s;
		  }
	  }
    }
  }

  .sm-box{
    height: 150px;
    border-top:5px solid #fff
  }

  .main-home-content{
    margin-top:50px;
    margin-bottom:50px;

    #main-home-img{
      margin-bottom: 30px;
    }

    #lined-text{
      margin-bottom: 30px;
    }
  }

  .main-home-content-right{
    margin-top:80px;
    margin-bottom:50px;
    color: $dark_blue;

    h2{
      font-size:35px;
      font-weight: bold;
      margin: 0px;

      span{
        font-weight: 300;
        display: block;
        margin-bottom: 50px;
      }

    }
    .bottom-text{
      font-size:26px;

      span{
        display: block;
      }
    }
  }
}

.main-content{
  margin:60px 0;
  font-size: 18px;
  position: relative;
  h1, h2{
    font-family: $heading-font;
    font-size:42px;
    color: $dark-grey;
    margin:0px 0 20px 0;
    line-height:1.1;
    text-transform: uppercase;
    font-weight: bold;
    span{
      font-family: $heading-font;
      color: $dark_yellow;
      display: block;
    }
  }

  .info-box{
    .icon-img{
      width:150px;
      height:150px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50%;
      margin:0 auto;
    }

    .figure{
      font-weight:bold;
      font-size:42px;
      margin-top:20px;
    }

    hr{
      width:50%;
      margin:20px auto ;
      border-color: #000;
    }

    p{
      font-weight:bold;
      font-size: 17px;
      margin-bottom: 0px;
    }

    a{
      color: $dark_yellow;
      text-transform: uppercase;
      font-size:17px;
      text-decoration: none;
      &:hover{
        color: #000;
      }
    }
  }

}

#main_banner{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:550px;
  overflow: hidden;
  position: relative;
  z-index:5;
  .top-text{
	  min-height: 45px;
  }
  h2:first-child{
    margin:0px;
    color: #fff;
    font-size:28px;//46px;
    text-transform: uppercase;
   // margin-top:15%;
    font-weight: bold;
    height: 44px;
    line-height: 1.3;
    padding-left: 15px;
    padding-right: 15px;

   // margin-top: 59px;
	position: relative;
	//background: rgba($dark-blue,.75);
	padding: 3px 10px 3px 10px;
	@media (min-width:992px){
		padding: 3px 10px 5px 10px;
	}
/*
	@media (max-width:480px){
		padding-left: 25px !important;
	}
*/
	top:0px;
    span{
	    font-size:28px;

    //  font-size: 72px;
/*
	    color: #fff;

	    display: block;
		position: absolute;
*/
	//	top: -50px;
    }
  }
  .banner_text_line_2{
	 background: rgba($dark-blue,.75);
	 color: $brand-yellow;
	 font-weight:bold;
	 float: left;
	 font-size: 28px;
	 padding: 10px 15px;
	 margin-top: 1px;
	padding-top: 5px;
	margin-left: -5px;
/*
	@media (max-width:480px){
		padding-left: 30px !important;
	}
*/
  }

  a{
    margin-top:30px;
    padding: 15px 40px;
    border-radius:18px;
    text-decoration: none;
    text-transform: uppercase;
    &:hover{
      color: $dark_blue;
    }
  }
}

// .img-container{
	.img-container__top-bar{
		background: rgba($dark-blue,.75);
		margin-left: -15px;
		margin-right: -15px;
		color: #fff;
		position: relative;
		padding: 5px 15px;
		text-align: left;
		font-size: 28px;
		font-weight: bold;
    *{
      font-family: $heading-font;
    }
		@media (max-width:480px){
			>div.top-text,
			>div.bottom-text{
				margin-left: 10px !important;
			}
		}
		@media (min-width:992px){
			border-left:1px solid rgba(255,255,255,.15);
			padding-left: 145px;

		}
		.top-text{

		}
		.bottom-text{
			position: absolute;
			margin: auto;
			left: 0px;
			background: rgba($dark-blue,.75);
			//transform: translateX(-50%);
			top: 100%;
			padding: 5px 15px;
			color: $brand-yellow;
			@media (min-width:992px){
				left: 130px;
			}
		}
	}
	.img-container__lower-bar{
		background: $dark-blue;
		position: absolute;
		bottom: 0;
		margin-left: -15px;
		margin-right: -15px;
		bottom: 0;
		height: 55px;
		padding: 5px 15px;
		width: calc(100% + 30px);
		text-align: center;
		@media (min-width:992px){
			border-left:1px solid rgba(255,255,255,.15);
		}
		.btn-white{
			border: 1px solid #fff;
			color: $dark_blue;
			display: inline-block;
			background: #fff;
			font-size: 18px;
			font-weight: bold;
      font-family: $heading-font;
			border-radius: 10px;
			margin-top: 0 !important;
			padding: 5px 15px;
			top: -30px;
			position: relative;
			text-decoration: none;
			text-transform: uppercase;
			transition: .25s all ease;
			@media (min-width:1200px){
				font-size: 25px;
			}
			&:hover,
			&:focus{
				background: $dark_blue;
				color: #fff !important;
			}
		}
	}
	.col-md-6,
	#main_banner{
		.img-container__lower-bar{
			text-align: right;
			.btn-white{
				margin-right: 75px;
			}
		}
	}
	#main_banner{
		.img-container__top-bar{
			max-height: 50px;
/*
			padding-top: 5px;
			padding-bottom: 5px;
*/
		}
		.img-container__lower-bar{

		}
		.btn-white{
			top:-45px;
			margin-right: 15px !important;
			margin-left: 15px !important;
			@media (min-width:992px){
				margin-right: 75px;
			}
		}
	}
// }

.bottom-imgs{
  position: relative;
  z-index: 5;
  .img-container{
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	overflow: hidden;
    .inner-box{
      background: rgba(255,255,255,0.85);
      display: inline-block;
      padding: 20px 50px 40px 20px;
      font-size: 28px;
      @media (min-width:$screen-sm-min){
        font-size: 3.2vw;//42px;
      }
      @media (min-width:$screen-lg-min){
        font-size: 40px;
      }
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      color: $dark_grey;
      top:60%;
      left:15%;
      line-height: 0.9;
      .bottom-text{
        color: $dark_yellow;
      }
      a{
        position: absolute;
        font-size: 13px;
        color: #fff;
        padding:15px;
        right:10px;
        bottom:-20px;
        border:1px solid $dark-blue;
        text-decoration: none;
        &:hover{
          color: $dark_blue;
          background: rgba(255,255,255,0.85) !important;
        }
      }
    }

    .find-depot {
        position: relative;
        top: 40%;
        .inner-box{
            position: unset;
            display: block;
            font-size: 30px;
            padding: 20px 20px 40px 20px;
            @media (min-width: $screen-sm-min) {
                margin-left: 90px;
            }
            @media (min-width: $screen-md-min) {
                margin-right: 90px;
            }
            form {
                input {
                  position: relative;
                  width: 80%;
                  padding: 5px;
                }
                button {
                  font-size: 26px;
                  background-color: $dark-blue;
                  position: absolute;
                  border-radius: unset;
                  &:hover {
                      color: #fff;
                  }
                }
            }
        }
    }
  }
}
//Home Page
