//Footer Styles
footer {
  position: relative;
  clear: both;
  color:#fff;
  @media (max-width:$screen-sm-max){
      display: flex;
      flex-direction: column;
  }
  @media (min-width:$screen-md-min){
      box-shadow: 101px 0 0 1px darken($dark-blue,12%);
  }

  #sidebar_footer_ctas {
    position: absolute;
    top: 0px;
    //background: $dark_blue;
    height: 100%;
   // max-width: 100px;
    width: 100px;
    z-index: 1099;

    @media (max-width:$screen-sm-max){
        position: relative;
        top: 0;
        height: 100%;
        width: 100vw;
        z-index: 99;
        background: $dark-blue;
        height: auto;
        float: left;
        order: 1;
    }
    a {
      color: #fff;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0px;
      font-size: 20px;
      margin-bottom: 20px;
      position: relative;
      transition: 0.2s;
      left: 0px;
      @media (min-width:$screen-sm-min) and (max-width:$screen-sm-max){
        margin-top: 5px;
        padding-top: 25px;
        width: 16.666%;
        float: left;
      }
      @media (max-width:$screen-xs-max){
        width: 25%;
        float: left;
      }
      &:hover {
        text-decoration: none;
        transition: 0.2s;
        //left:2px;
        color: $yellow;
      }

    }

    #back-to-top {
      width: 100%;
      color: #fff;
      border-bottom: 1px solid #fff;
      padding-bottom: 25px;
      margin: 25px 0;
      transition: 0.2s;
      @media (max-width:$screen-sm-max){
        float: left;
      }
      @media (min-width:$screen-sm-min) and (max-width:$screen-sm-max){
          border-bottom: 0 none;
          border-right: 1px solid #fff;
          width: 33.333%;
          margin: 0;
          padding-top: 25px;
      }
      &:hover {
        transition: 0.2s;
        color: $yellow;
        cursor: pointer;
        .fa {
          color: $yellow;
        }
      }

      .fa {
        display: block;
        text-align: center;
        font-size: 20px;
      }

      span {
        display: block;
        text-align: center;
      }
    }
  }
}

#footer-links{
  border:1px solid darken($dark-blue,12%);
  padding:30px 10px;
  margin-top:90px;
  background: $dark-blue;
  position: relative;
  z-index:1;
  @media (max-width:$screen-sm-max){
    order: 0;
  }
  ul{
    padding: 0px;
    margin:0px;
    overflow: hidden;
/*
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    -webkit-column-gap: 5px;
     -moz-column-gap: 5px;
          column-gap: 5px;
*/
    margin-top: 15px;
    @media (min-width:$screen-lg-min){

        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
     //   -webkit-column-gap: 5px;
     //-moz-column-gap: 5px;
     //     column-gap: 5px;

    }
    li{
      list-style:none;
      margin-bottom: 15px;
      @media(max-width:1200px){
        float: left;
      }
      @media(max-width:769px){
        float: none;
      }

      @media (min-width:$screen-sm-min) and (max-width:$screen-md-max){
          display: inline-block;
           margin-left: -4px;
           width: 33%;
      }
      @media (min-width:$screen-md-min) and (max-width:$screen-md-max){
          width: 50%;
      }
      @media (min-width:$screen-lg-min){
          display: inline-block;
          width: 100%;
      }
      a{
        color: #fff;
        font-size:15px;
        text-decoration: none;
        transition: 0.2s;
        &:hover{
          color: $dark_yellow;
          transition: 0.2s;
        }
      }
    }
  }
}

#newsletter{
  border:1px solid darken($dark-blue,12%);
  margin-top:90px;
  h3{
    margin-top: -30px;
	margin-left:10px;
	padding-left:30px;
    background: #fff;
    width: 90%;
    text-transform: uppercase;
    color: $dark-grey;
    font-size:3.8vw;//52px;
    font-family:$heading-font;
    font-weight: 300;
	text-align: left;
	@media (min-width:$screen-lg-min){
    	font-size: 43px;
	}
  }
  .img-link {
	display:block;
	margin-top:-30px;
    img{
      max-width:162px;
      padding-left: 25px;
      @media (max-width:768px){
        padding-left:0px;
      }
    }
  }
  p {
	padding: 0 40px;
    color: #000;
  }
  a{
    //border:1px solid $yellow;
  }
  @media (min-width:$screen-sm-min) and (max-width:$screen-md-max){
      .row{
    h3{
        background: transparent;
        font-size: 26px;
    	margin-top: 10px;
    	margin-left: 0;
    	padding-left: 10px;
    }
    p{
        padding: 0 10px;
    }
	a{
    	//border: 0 none;
	}
	.img-link{
    	margin-top: 10px;
    	img{
    	    max-width: 100px;
    	}
	}
	}
  }
}
  .contact-details{
    margin:20px 0;
    text-align: left;
  //  overflow: auto;
    @media (max-width:$screen-sm-max){
        padding-left: 15px;
        padding-right: 15px;
    }
    strong{
      color: #000;
    }
    .main-address{
      margin-top:30px;
      @media (min-width:$screen-lg-min){
	    padding-left: 45px;
	  }
      @media (max-width:992px){
        margin-top: 0px;
      }
      .fa{
        float:left;
        font-size: 60px;
        color: $dark-grey;
        margin-right:5px;
        margin-top:-3px;
      }
      div{
        //float:left;
        p{
          margin:0px;
          color: #000;
        }
      }
    }
  }
  .main-contact-details{
    margin-top:30px;
    @media (max-width:992px){
      margin-top:0px
    }
    a{
      color: #000;
      transition: 0.2s;
      display: block;
      text-decoration: none;
      &:hover{
        color: $dark-blue;
        transition:0.3s all ease;
      }
      strong{
        margin-right:5px;

      }
    }
  }
  .links-container{
      overflow: hidden;
  }

footer{
    .copyright{
        margin-bottom: 15px;
        overflow: hidden;
        padding:50px 15px;
        font-weight:bold;
        @media (max-width:$screen-sm-max){
            order: 2;
        }
        ul{
            padding:0px;
            margin:0px;
            float: left;
            li{
              display: inline-block;
              list-style-type: none;
             // border-right:1px solid darken($dark-blue,12%);
              a{
                color: #000;
                margin:0px 10px;
                display: inline-block;
                text-decoration: none;
        		transition:0.3s all ease;
                    &:hover{
                      color: $dark-blue;
                    }
                }
            }
        }

      img{
        margin:-15px 0 0 5px;
      }
      .border-top{
    	border-top:1px solid darken($dark-blue,12%);
    	padding-top:15px;
      }
      #blue2{
        float:right;
        font-weight:bold;
    	color:#000;
        @media (max-width:600px){
          float:none;
          text-align: center;
          margin-top:30px
        }
      }
      p {
    	padding-top:15px;
        @media (max-width:600px){
          margin:10px auto;
          float:none;
          text-align: center;
        }
      }
    }
}

.footer-line{
  height: 100%;
  position: absolute;
  width: 1px;
  left: 25%;
  border-right: 1px solid darken($dark-blue,12%);
  z-index: 0;
}
//Footer Styles
