//Header Styles
header{
  z-index: 1099;
  position: relative;
}


.home .wrap{
  height:1000px
}

.side-menu-bg {
  background-color: rgba(32, 32, 32, 0.8);
  position: fixed;
  width:100px;
  height:100%;
  left:0;
  z-index: 999;
  transition:0.3s left ease;
  @media (max-width:$screen-sm-max){
      display: none;
  }
}

.basket-btn{
 // display: none;
 display: inline-block;
}

/*
.tax-product_cat .basket-btn, .single-product .basket-btn, .request-a-quote .basket-btn, .basket .basket-btn{
  display: inline-block;
}
*/

.picklist-btn{
  color: $dark-blue !important;
  border-radius: 10px;
  background: $yellow;
  padding:5px 10px;
  @media (max-width:768px){
    padding: 4px;
  }
  @media (min-width:$screen-lg-min + 50){
      padding: 5px 10px 3px 10px;
  }
  transition: 0.2s;
  border:1px solid $yellow;
  z-index: 10;
  font-size:14px !important;
  margin-right:10px;
  position: relative;
  top:0px;
  span{
      color: $dark_blue !important;
      float: left;
      margin-top: -1px;
        &.fa{
            padding-top: 3px;
        }
  }
  &:hover{
    text-decoration: underline;
  }
}
.basket-btn{
    @media (max-width:$screen-xs-max){
        position: absolute;
        right: 40px;
    }
}



.toggle-state {
	opacity:1;
	transition:0.3s opacity ease;
	&.off {
		opacity:0;
	}
}


.close-btn {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;

	#toggle-btn{
		font-size: 40px;
		text-align: center;
		margin: 0 auto;
		width: 100px;
        display:block;
		//  transform: rotate(180deg);
		background: $yellow;
		height: 75px;
		position: relative;
		line-height: 66px;
		cursor:pointer;
		transition: 0.3s;
        color: #000;
		&:hover{
		  color: $dark_blue;
          #open-text, #close-text {
            color: $yellow;
          }
		}
		.fa {
			transition: 0.3s;
			//transform: rotate(180deg);
		}
		#toggle-text{
			line-height: 51px;
			min-height: 51px;
			position: relative;
			top: 8px;
            font-weight: bold;
		}
		#open-text, #close-text {
			position:absolute;
			top:0;
			width: 100%;
			font-size: 14px;
			color: #fff;
			transition: 0.3s opacity;
		}
		#open-text {
			opacity:0;
		}
		#close-text {
			opacity:1;
		}
	}
}

.container {
	transition:0.3s padding-left ease;
	@media (min-width:$screen-md-min){
	    padding-left:110px;
	}
}

body.hub-closed {
  #toggle-text{
    background: rgba(32,32,32,.8);
  }
	.close-btn {
		#toggle-btn{
			transition: 0.3s;
			.fa {
				transform: rotate(0deg)
			}
			#open-text {
				opacity:1;
			}
			#close-text {
				opacity:0;
			}
		}
	}
	#side-nav, .side-menu-bg{
	left: -110px;
	}
	.container {
		padding-left:15px;
	}
	#home-images .boxes {
		border-right: 6px solid hsla(0,0%,100%,.8);
	}
}

#side-nav{
  width: 100px;
  //background-color: rgba(0, 28, 92, 0.8);
  height: 100%;
  position: absolute;
  z-index: 999;
  left:0px;
  top: 131px;
  @media (min-width:$screen-lg-min){
      top: 131px;
  }
  transition: 0.3s;
  @media (max-width:$screen-sm-max){
    display: none;
  }
  #side-nav-inner{
    position: relative;
    height: 100%;

    .close-btn{
      cursor:pointer;
    }

    #close-text{
      color: #fff;
      text-align: center;
      margin: 15px 0;
      border-bottom: 1px solid #fff;
      padding-bottom: 15px;
      transition: 0.2s;
      &:hover{
        color: $yellow;
      }
    }
  }

  .nav-side{
    padding:0px;
    margin-top:30px;
    margin-bottom:74px;
  }

  li{
    list-style: none;

    a {
      color: #fff;
      font-size: 14px;
      font-family: $heading-font;
      font-weight: 700;
      display: block;
      position: relative;
      transition: .2s;
      left: 0;
      padding: 15px 0px 15px 8px;

      &:hover, &:focus{
        text-decoration:none;
        transition: 0.2s;
        //left:2px;
        background: $yellow;
        color: $dark-blue

      }
    }
  }

  li.active{
    background: $yellow;

    a{
      color: $dark-blue;
    }
  }

  #division-menu,
  #division-menu-mob{
      background: rgba(0,0,0,.5);
      background: $dark-blue;
      #division-toggle,
      #division-toggle-mob{
          color: #fff;
          line-height: 51px;
          margin: 0 0 0 8px;
          font-weight: bold;
          cursor:pointer;
        }
        .divisions{
          display: none;
          margin-top: 0;
        }
        &.primaryNav{
            .divisions{
                display: block;
            }
        }
  }



  #sidebar_ctas{
    padding:30px 0 3px 0;

    a{
      color: #fff;
      display: block;
      width: 100%;
      text-align: center;
      padding: 0px;
      font-size: 20px;
      margin-bottom: 20px;
      position: relative;
      transition: 0.2s;
      left:0px;

      &:hover{
        text-decoration:none;
        transition: 0.2s;
        //left:2px;
        color: $yellow;
      }

    }
  }
}


.hub-mob{
    background: rgba(32, 32, 32, .8);
    border-top: 1px solid $dark-grey;
    h2{
        color: #fff;
        text-align: left;
        padding: 15px 20px;
        font-size: 18px;
        margin: 0;
    }
}
#division-menu-mob{
    background: darken($dark-blue, 10%);
    #division-toggle-mob{
          width: 100%;
          background: $dark-grey;
          color: #fff;
          margin-bottom: 0;
          padding: 15px 20px;
          font-size: 16px;
          text-align: left;
    }
    .mobile-menu ul{
        background: transparent;
    }
    .divisions{
        padding: 0;
    }
}
.home #side-nav {
	top:131px;
	@media (min-width:$screen-lg-min){
    	top:131px;
	}
}
/* #side-nav.closed, .side-menu-bg.closed{
  left: -110px;
} */

.top-nav-area {
  width: 100%;
  position: absolute;
  min-height:135px;
  background:rgba(3, 67, 140, 0.8);
  padding-top: 20px;
  padding-right: 20px;
  @media (min-width:$screen-md-min){
    min-height: 131px;
  }
  @media (max-width:560px){
      min-height: 115px;
  }
  #site-logo {

    img{
      width:100%;
      max-width:300px;
      //min-width:200px;
    }
    @media (min-width:$screen-sm-min) and (max-width:$screen-md-max){
        padding-left: 15px;
        padding-top: 15px;
        display: block;
    }

/*
    @media (min-width:1005px) and (max-width:$screen-lg-min){
        padding-top: 5px;
        display: block;
    }
*/
    @media (min-width:601px) and (max-width:$screen-xs-max){
        display: block;
        max-width: 290px;
    }
    @media (min-width:480px) and (max-width:600px){
        max-width: 210px;
        display: block;
    }

    @media (min-width:$screen-lg-min){
        position: absolute;
        max-width: 80%;
        top:80%;
        transform: translateY(-50%);
    }
    @media (min-width:$screen-lg-min + 100){
        top:77%;
    }
    @media (min-width:$screen-lg-min + 150){
        top:75%;
    }
  }
  	  .header-phone{
		span#num,
		span#num a[href^="tel"]{
			font-size: 20px !important;
			color: $yellow;
			font-weight: 700;
			@media (min-width:$screen-sm-min){
    			font-size: 28px !important;
			}
			@media (min-width:$screen-lg-min){
    			pointer-events: none;
			}
		}
		#national,
		#national a[href^="tel"]{
            color: #fff;
            font-size: 22px !important;
            @media (min-width:$screen-lg-min + 200){
    			pointer-events: none;
    			font-size: 28px !important;
			}
		}
	  }

  .nav-sub-menu {
/*
      &:before{
        content: "";
        display: block;
        border-bottom: 1px solid #6f82a7;

        position: absolute;
        top: 50%;
        right: 100%;

        @media (min-width:$screen-lg-min){
           width: 50px;
        }
      }
*/
    //width: 100%;
    float: right;
    text-align: right;
    position: relative;
    padding-bottom: 5px;
   // border-top:2px solid #6f82a7;

    padding-left: 0;
    margin-top: 5px;
    padding-top: 6px;

    @media (min-width:$screen-lg-min){
        margin-top: 0;
        padding-top: 0;
    }
    @media (min-width:1650px){
        margin-top: 5px;
        padding-top: 6px;
    }
    li {
      display: inline-block;
      list-style: none;
      padding: 0px 11px;
      @media (min-width:$screen-sm-min) and (max-width:$screen-lg-min + 200){
        padding: 0 5px;
      }
      &:last-of-type {
        padding-right:0px !important
      }
      &.active a{
        color: $yellow;
        text-decoration: none;
        transition: 0.2s;
      }

      a {
        color: #fff;
        font-size: 17px;
        @media (min-width:$screen-sm-min) and (max-width:$screen-lg-min + 200){
            font-size: 15px;
        }

        font-family: $heading-font;
        transition: 0.2s;
        padding: 5px 0;
		font-weight:700;
        display: block;
        &:hover {
          color: $yellow;
          text-decoration: none;
          transition: 0.2s;
        }
      }
      .sub-menu{
        display: none;
        position: absolute;
        padding:0px;

        li{
          display: block;
          vertical-align: top;
          text-align: left;
          background: rgba(3,67,140,.8);
          transition: 0.2s;
          &.active{
            background: $yellow;
            text-decoration: none;
            transition: 0.2s;
            a{
              color: $dark-blue;
            }
          }
          &:first-of-type{
            margin-top:14px;
            @media (max-width:1200px){
              margin-top:9px;
            }
          }
          &:hover{
            transition: 0.2s;
            background: $yellow;
            a{
              color: $dark_blue;
            }
          }
          a{
            color: $yellow;
            display: block;
            padding:5px
          }
        }
      }
    }
    .menu-item-has-children:hover .sub-menu{
      display: block;
    }

  }

  #sub-text {
    text-align: right;
  //  position: relative;
    top:7px;
    padding-top: 5px;
    >div{
        @media (min-width:$screen-lg-min){
            border-bottom: 1px solid rgb(222,231,240);
        }
    }
    .search{
      color: #000;
      font-family: fontAwesome;
      font-size: 15px;
      display:inline-block;
      cursor:pointer;
      float: right;
      padding-right: 15px;
      form{
        background: #fff;
     //   padding: 6px 8px;
        margin-bottom: 17px;
        border-radius: 10px;
        .searchbox {
          font-family: $roboto !important;
          float: left;
		  padding:5px 10px;
		  @media (min-width:$screen-sm-min){
    		  max-width: 165px;
		  }
		  @media (min-width:$screen-md-min){
                max-width: 185px;
          }
//		  padding-left:10px;
        }
        input{
          background: transparent;
          color: $dark-blue;
          border:none;
          outline: none;

        }
		.searchsubmit {
			 color: $dark-blue;
			 font-size:20px;
			 padding-top:0px;
			 padding-right: 10px;
			// padding-right:20px;
			 font-family:fontAwesome !important;
		}
        ::-webkit-input-placeholder{
          color: $dark-blue;
        }
        ::-moz-placeholder{
          color: $dark-blue;
        }
        :-ms-input-placeholder{
          color: $dark-blue;
        }
        :-moz-placeholder{
          color: $dark-blue;
        }
      }
	  //@media (min-width: 1400px) {
       //         display:inline-block;
       //     }
    }

    span {
      color: #fff;
      font-size: 15px;
      padding-right: 5px;
    }
    a {
      color: $yellow;
      display: inline-block;
      font-size: 20px;
      margin-left:10px;
      transition: 0.2s;
      &:hover{
        transition: 0.2s;
        color: #fff;
      }
    }

  }
}


.header-right {
	padding-top:10px;
}

.lower-nav-wrapper{
    clear: both;
    @media (max-width:1650px){
      padding-top: 11px;
    }
    @media (max-width:1400px){
      padding-top: 14px;
    }
  @media (max-width:1200px){
    padding-top: 0px;
  }
}
.lower-nav {
	//border-top:1px solid rgb(222,231,240);// #6f82a7;
	.icon-link {
		padding-bottom: 5px;
		padding-top: 6px;
		margin-top: 10px;
		font-size: 14px;
		//display:none;
		@media (min-width: $screen-lg-min) {
			font-size: 14px;
			display:inline-block;
			margin-top: 0;
		}
		@media (min-width: 1650px){
    		margin-top: 10px;
		}
		@media (min-width: 1700px) {
            font-size: 17px;
        }
	}

  .socials{
    color: #fff !important;
    @media (max-width:$screen-md-max){
        padding-left: 15px;
    }
    @media (min-width:$screen-sm-min) and (max-width:$screen-md-max){
        padding-left: 30px;
    }

    span{
      margin-right:15px;
      font-size:18px;
    }
  }


}

.top-nav-area{
  @media (max-width:768px){
    min-height:115px !important;
  }
}

.top-nav-area .search.search-mobile{
    display: block !important;
    float: left;
    width: 100%;
    position: absolute;
    top: 100%;
    background: $yellow;
    padding: 10px;

      color: #000;
      font-family: fontAwesome;
      font-size: 15px;
      display:inline-block;
      cursor:pointer;
      float: right;
      padding-right: 15px;
      form{
        background: #fff;
     //   padding: 6px 8px;
        margin-bottom: 0;
        border-radius: 10px;
        float: left;
        width: 100%;
        .searchbox {
          font-family: $roboto !important;
          float: left;
		  padding:5px 10px;
		  width: calc(100% - 60px);
//		  padding-left:10px;
        }
        input{
          background: transparent;
          color: $dark-blue;
          border:none;
          outline: none;

        }
		.searchsubmit {
			 color: $dark-blue;
			 font-size:20px;
			 float: right;
			 padding-top:0px;
			 padding-right:20px;
			 font-family:fontAwesome !important;
		}
        ::-webkit-input-placeholder{
          color: $dark-blue;
        }
        ::-moz-placeholder{
          color: $dark-blue;
        }
        :-ms-input-placeholder{
          color: $dark-blue;
        }
        :-moz-placeholder{
          color: $dark-blue;
        }
      }


    @media (min-width:$screen-sm-min){
        display: none !important;
    }
}

.icon-link {
	display:inline-block;
	color:#fff;
	.fa{
		color:$yellow;
		font-size:20px;
        transition: 0.2s;
        &:hover{
          transition: 0.2s;
          color: #fff;
        }
	}
	&:hover {
		text-decoration: none;
		color: $yellow;
	}
    a{
      margin-right:5px;
  }
}

#top-image-main{
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.spacer{
  height: 200px;
}

.mobile-menu, #menu-toggle{
  display: none;
}

@media (max-width:$screen-md-max){
    .mobile-menu{
        .menu-item-has-children{
            cursor: pointer;
            position: relative;
            overflow: hidden;
            padding-left: 25px;
            margin-left: 0 ;
            &:after{
                content: "\f0d7";
                display: block;
                font-family: fontawesome;
                position: absolute;
                right: 20px;
                color: #fff;
                top:7px;
                transform: rotate(-90deg);
                transition: .3s all ease;
            }
            >ul.sub-menu{
                margin-left: -25px;
                padding-left: 10px
            }
            &.opened{
               &:after{
                   transform: rotate(0deg);
               }
                >ul.sub-menu{
                    display: block;
                    background-color: rgba(255,255,255,.1);
                    float: left;
                    width: 100vw;
                    margin-bottom: 10px;
                }
            }
            >a{
                float: left;
                width: 90%;
                width: calc(100% - 100px) !important;
            }

        }
    }
     .nav-sub-menu{
        .menu-item-has-children{
            cursor: pointer;
            position: relative;
            //overflow: hidden;
            padding-left: 25px;
            margin-left: 0;
            &:after{
                content: "\f0d7";
                display: block;
                font-family: fontawesome;
                position: absolute;
                right: 10px;
                color: #fff;
                top:7px;
                transform: rotate(-90deg);
                transition: .3s all ease;
            }
/*
            >ul.sub-menu{
                margin-left: -25px;
                padding-left: 10px
            }
*/
            &.opened{
               &:after{
                   transform: rotate(0deg);
               }
                >ul.sub-menu{
                    display: block;
                }
            }
            >a{
               // float: left;
               text-align: left;
               margin-right: 25px;
               white-space: nowrap;
                width: 90%;
                width: calc(100% - 50px);
            }

        }
    }
}

.top-nav-area .nav-sub-menu li.mob-home-link{
  display:none;
  @media (max-width:992px){
    display: block !important;
  }
}

.home-li{
  @media (max-width:992px){
    display: none !important;
  }
}

#division-menu-mob .divisions{
  display: none;
}
